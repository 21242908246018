const diagnosticToolsUrology = process.env.REACT_APP_PDF_URL + 'dt/urology/';

export const diagnosticToolsUrologyData = [
  {
    id: 0,
    title: 'Benign Prostatic Hyperplasia',
    pdf_link:
      diagnosticToolsUrology +
      'Benign_Prostatic_Hyperplasia_Diagnostic_Tool.pdf',
  },
  {
    id: 1,
    title: 'Epididymitis',
    pdf_link: diagnosticToolsUrology + 'Epididymitis_Diagnostic_Tool.pdf',
  },
  {
    id: 2,
    title: 'Male Infertility',
    pdf_link: diagnosticToolsUrology + 'Male_Infertility_Diagnostic_Tool.pdf',
  },
  {
    id: 3,
    title: 'Overactive Bladder',
    pdf_link: diagnosticToolsUrology + 'Overactive_Bladder_Diagnostic_Tool.pdf',
  },
  {
    id: 4,
    title: 'Prostatitis',
    pdf_link: diagnosticToolsUrology + 'Prostatitis_Diagnostic_Tool.pdf',
  },
  {
    id: 5,
    title: 'Pyelonephritis',
    pdf_link: diagnosticToolsUrology + 'Pyelonephritis_Diagnostic_Tool.pdf',
  },
  {
    id: 6,
    title: 'Urethritis and Cystitis',
    pdf_link:
      diagnosticToolsUrology + 'Urethritis_Cystitis_Diagnostic_Tool.pdf',
  },
  {
    id: 7,
    title: 'Urinary Incontinence',
    pdf_link:
      diagnosticToolsUrology + 'Urinary_Incontinence_Diagnostic_Tool.pdf',
  },
  {
    id: 8,
    title: 'Urinary Retention',
    pdf_link: diagnosticToolsUrology + 'Urinary_Retention_Diagnostic_Tool.pdf',
  },
];

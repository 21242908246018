import React, {memo, useEffect, useRef, useState} from 'react';
import styled, {keyframes, css} from 'styled-components';
import {GenericPickerProps} from '../../interfaces/components/picker';
import Select from 'react-dropdown-select';
import {isSmallHeight} from '../../utils/responsive';

const hide = (spaceToBottom: number) => keyframes`
    from {
        height: ${spaceToBottom}px !important;
        opacity: 1;
    }
    to {
        height: 0;
        opacity: 0;
    }
`;

const show = (spaceToBottom: number) => keyframes`
    from {
        height: 0;
        opacity: 0;
    }
    to {
        height: ${spaceToBottom}px !important;
        opacity: 1;
    }
`;

const Label = styled.label<any>`
  display: flex;
  align-items: center;
  font-size: ${({theme}) => theme.text.s8};
  margin-bottom: 8px;
  color: ${({theme}) => theme.text.light};
  ${({labelStyle}) => labelStyle && {...labelStyle}}

  &::after {
    content: ${({isRequired}) => (isRequired ? "'*'" : "''")};
    color: ${({theme}) => theme.text.error};
    margin-left: 4px;
  }
`;

/**
 * Outer container for the picker component.
 */
const Container = styled.div`
  width: 100%;
  position: relative;
`;

const StyledSelect = styled(Select)<{isOpen?: boolean; spaceToBottom: number}>`
  background-color: ${({theme}) => theme.border + '11'};
  border: 1px solid ${({theme}) => theme.textInput.border} !important;
  border-radius: 100px !important;
  min-height: ${isSmallHeight ? 37 : 42}px !important;
  color: ${({theme}) => theme.text.light}!important;
  font-size: ${({theme}) => theme.text.s8} !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:focus-within {
    border-color: ${({theme}) => theme.textInput.border + '99'};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  .react-dropdown-select-clear,
  .react-dropdown-select-dropdown-handle {
    color: ${({theme}) => theme.text.light};
    padding: 0 8px;
  }

  .react-dropdown-select-type-single {
    padding: 0 5px;
  }
  .react-dropdown-select-input {
    color: ${({theme}) => theme.text.light};
    font-size: ${({theme}) => theme.text.s8};
    &::placeholder {
      color: ${({theme}) => theme.text.dark};
      opacity: 0.8;
    }
  }

  .react-dropdown-select-dropdown {
    position: absolute;
    left: 0;
    border: none;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    z-index: 9;
    color: ${({theme}) => theme.text.dark} !important;
    border-radius: 8px;
    background: linear-gradient(
      360deg,
      ${({theme}) => theme.text.light + 'DD'} -68.52%,
      ${({theme}) => theme.text.light + 'CC'} 122.02%
    );
    filter: drop-shadow(0px 4px 8px ${({theme}) => theme.primary + '22'});
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }

  .react-dropdown-select-item {
    border-bottom: 1px solid ${({theme}) => theme.text.border + '44'};
    padding: 3% 5%;
    :hover {
      color: ${({theme}) => theme.primary + '77'};
    }
  }
  .react-dropdown-select-item.react-dropdown-select-item-selected,
  .react-dropdown-select-item.react-dropdown-select-item-active {
    background: ${({theme}) => theme.secondary};
    border-bottom: 1px solid ${({theme}) => theme.text.light};
    color: ${({theme}) => theme.text.light};
    font-weight: 400;
  }
  transition: all 0.3s ease-out;
  .react-dropdown-select-dropdown {
    height: ${({spaceToBottom}) => spaceToBottom}px;
    ${({isOpen, spaceToBottom}) =>
      isOpen
        ? css`
            animation: ${hide(spaceToBottom)} 310ms ease-in-out;
          `
        : css`
            animation: ${show(spaceToBottom)} 310ms ease-in-out;
          `};
  }
  .react-dropdown-select-option {
    transition: all 0.3s ease-out;
  }
`;

const windowHeight = window.innerHeight;

/**
 * GenericPicker component to select items from a list.
 *
 * @param style
 * @param onValueChange
 * @param list
 * @param labelKey
 * @param valueKey
 * @param label
 * @param value
 * @param disabled
 * @param isRequired
 * @param customComponent
 * @param isUnselectAllowed
 * @param containerStyle
 * @param {GenericPickerProps} props - Component props.
 * @returns {JSX.Element} - JSX element representing the GenericPicker component.
 */
const GenericPicker: React.FC<GenericPickerProps> = ({
  onChange,
  list,
  labelKey,
  valueKey,
  values = [],
  isRequired,
  containerStyle,
  label,
  labelStyle,
  className,
  disabledNoOpacity,
  ...props
}): JSX.Element => {
  const divRef = useRef(null);
  const [spaceToBottom, setSpaceToBottom] = useState(0);

  const calculateSpace = () => {
    if (divRef.current) {
      const divRect = divRef.current.getBoundingClientRect();
      const minHeight = (isSmallHeight ? 37 : 42) * 3;
      const maxHeight = 400;
      const space = Math.max(
        Math.min(
          windowHeight -
            divRect.bottom +
            (isSmallHeight ? 37 : 42) / (props?.selectAll ? 3 : 2),
          maxHeight,
        ),
        minHeight,
      );

      setSpaceToBottom(space);
    }
  };

  useEffect(() => {
    calculateSpace();
  }, [values]);

  const getByPath = (object, path) => {
    if (!path) {
      return;
    }

    return path.split('.').reduce((acc, value) => acc[value], object);
  };

  const onSearch = ({state, methods}) => {
    const regexp = new RegExp(methods.safeString(state.search), 'i');
    return methods
      .sortBy()
      .filter(item =>
        regexp.test(getByPath(item, 'name') || getByPath(item, 'name')),
      );
  };

  return (
    <Container ref={divRef} style={containerStyle}>
      {label && (
        <Label bold labelStyle={labelStyle} isRequired={isRequired}>
          {label}
        </Label>
      )}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/*@ts-expect-error*/}
      <StyledSelect
        className={className}
        searchFn={onSearch}
        options={list}
        values={values || []}
        isRequired={isRequired}
        labelField={labelKey || 'label'}
        valueField={valueKey || 'value'}
        spaceToBottom={spaceToBottom}
        onChange={(value: any) => onChange && onChange(value)}
        {...props}
      />
    </Container>
  );
};

export default memo(GenericPicker);

import React, {memo, useEffect, useRef, useState} from 'react';
import styled, {css, useTheme} from 'styled-components';
import {Theme} from '../../theme/lightTheme';
import Icon from '../icon';
import GenericButton from '../button';
import {DrugFilterItemProps} from '../../interfaces/components/drugFilterItem';
import {DrugItemType} from '../../interfaces/api/tabs';
import {colors} from '../../theme/colors';
import {motion} from 'framer-motion';
import {tr} from '../../translation';
import ErrorView from '../errorView';
import {AnimatedDiv, BlurContainer} from '../../screens/content/news';
import DrugFilterSkeleton from '../drugFilterSkeleton';
import Skeleton from 'react-loading-skeleton';
import {isSmallHeight} from '../../utils/responsive';

const zoomInOut = {
  zoomIn: {scale: 35, opacity: 1},
  zoomOut: {scale: 0, opacity: 0},
};
const fadeInOut = {
  fadeIn: {opacity: 1},
  fadeOut: {opacity: 0},
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;
const Container = styled(motion.button)`
  padding: 0;
  border: none;
  border-radius: 50%;
  background-color: ${({theme}) => theme.homeBackground};
  box-shadow: 0 4px 8px ${({theme}) => theme.shadow};
  z-index: 3;
  display: flex;
  position: relative;
  width: ${isSmallHeight ? 2.7 : 3}rem;
  aspect-ratio: 1;
  margin: 0.6rem;
  @media (max-width: 550px) {
    width: 2.7rem;
    margin: 0.3rem;
  }
`;
const ContentContainer = styled.div<{isSelected: boolean}>`
  width: 97%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    ${({theme, isSelected}) =>
        isSelected ? colors.primary : colors.primary + 'AA'}
      0%,
    ${({theme, isSelected}) =>
        isSelected ? colors.secondary : colors.secondary + 'AA'}
      100%
  );
  backdrop-filter: blur(3px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
`;
const Label = styled.p<{isModalOpen: boolean}>`
  font-size: ${({theme}) => theme.text.s8};
  font-weight: ${({isModalOpen}) => (isModalOpen ? 500 : 'normal')};
  color: ${({theme, isModalOpen}) =>
    isModalOpen ? theme.text.light : theme.text.black};
`;
export const FilterItemLabel = styled.p<{isSelected: boolean}>`
  font-size: ${({theme}) => theme.text.s8};
  color: ${({theme, isSelected}) =>
    isSelected ? theme.text.light : theme.text.title};
  text-align: center;
`;
export const FilterContainer = styled.div<{flex: boolean}>`
  ${({flex}) =>
    flex &&
    `
     display: flex;
     margin-bottom: auto;
    `};

  flex: 1;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  padding: 1rem 1rem 5rem 1rem;
  overflow-y: scroll;
  height: 100%;
`;
export const FilterItemContainer = styled.div<{
  isLeft: boolean;
  isSelected: boolean;
}>`
  border-color: ${({theme}) => theme.primary};
  border-bottom-color: ${({theme}) => theme.text.grey + '22'};
  border-bottom-style: solid;
  background-color: ${({isSelected, theme}) =>
    isSelected ? theme.primary + '77' : 'transparent'};
  display: flex;
  flex: 1 1 calc(50% - 1px);
  align-items: center;
  ${({isLeft, theme}) =>
    !isLeft &&
    css`
      border-left-width: 1px;
      border-left-color: ${theme.primary};
      border-left-style: solid;
    `};
`;
export const FilterItem = styled(motion.button)`
  padding: ${isSmallHeight ? 1 : 1.5}rem ${isSmallHeight ? 0.5 : 1}rem;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const Overlay = styled(motion.div)<{isModalOpen: boolean}>`
  width: 10vh;
  aspect-ratio: 1;
  border-radius: 50%;
  position: absolute;
  background-color: ${({theme}) => theme.primary + 'AA'};
  top: -20%;
  left: -20%;
  z-index: 1;
  pointer-events: ${({isModalOpen}) => (isModalOpen ? 'auto' : 'none')};
`;
const ModalContainer = styled(motion.div)<{
  positionY: number;
  positionX: number;
  positionWidth: number;
  numberOfItems: number;
  positionHeight: number;
  availableSpace: number;
  isModalOpen: boolean;
}>`
  height: ${({positionY, positionHeight, theme}) =>
    `calc(${theme.isMobile ? 83 : 97}vh - ${positionY || 0}px - ${positionHeight || 0}px)`};
  width: ${({positionWidth, numberOfItems}) =>
    numberOfItems * positionWidth + 3}px;
  position: absolute;
  top: ${({positionHeight}) => positionHeight}px;
  left: ${({positionWidth, positionX, numberOfItems, availableSpace}) => {
    const totalPadding = numberOfItems * positionWidth - availableSpace;
    return -totalPadding / 2 - positionX;
  }}px;
  z-index: 2;
  border-radius: 20px;
  pointer-events: ${({isModalOpen}) => (isModalOpen ? 'auto' : 'none')};
  @media (max-width: 550px) {
    width: ${({positionWidth, numberOfItems}) =>
      numberOfItems * positionWidth}px;
  }
`;
const MaskContainer = styled.div<any>`
  height: ${({height}) => height || 0}px;
  width: ${({width}) => width || 0}px;
  position: absolute;
  top: ${({height}) => -height || 0}px;
  left: ${({x, width, availableSpace}) => {
    const totalPadding =
      Math.floor(availableSpace / width) * width - availableSpace;
    return x + totalPadding / 2;
  }}px;
  align-self: center;
  background-color: ${({theme}) => theme.homeBackground};
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  align-items: flex-end;
  justify-content: flex-end;
`;
const MaskIconContainer = styled.div<any>`
  position: absolute;
  bottom: -4px;
  ${({isLeft, position, isStart}) =>
    isLeft
      ? `left:${-0.4 * (position?.width || 0) + 3}px;transform: rotate(${
          isStart ? '0' : '-90'
        }deg)`
      : `right:${-0.4 * (position?.width || 0) + 3}px`};
`;
const ModalContentContainer = styled.div<any>`
  height: 100%;
  width: 100%;
  background-color: ${({theme}) => theme.homeBackground};
  border-radius: 20px;
  ${({isFirst}) => isFirst && 'border-top-left-radius:0'};
  ${({isLast}) => isLast && 'border-top-right-radius:0'};
  overflow: hidden;
  margin-left: -1px;
  @media (max-width: 550px) {
    margin-left: 0;
  }
`;
const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${({theme}) => theme.homeBackground};
  padding: 1rem;
  border-radius: 20px;
`;
const ConfirmSelection = styled(GenericButton)`
  display: flex;
  align-self: center;
`;
const EmptyText = styled.p`
  font-size: ${({theme}) => theme.text.s9};
  color: ${({theme}) => theme.text.light};
  width: 80%;
  padding-vertical: 4%;
  text-align: center;
`;

/**
 * Drug Filter Item component.
 *
 * @param {DrugFilterItemProps} props - The Drug Filter Item component props.
 * @returns {JSX.Element} The rendered Fab component.
 */
const DrugFilterItem: React.FC<DrugFilterItemProps> = ({
  style,
  name,
  isSkeleton,
  isLoading,
  data,
  isError,
  isLastPage,
  selectedDrug,
  onConfirmSelection,
  onPress,
  isFilterModalShouldClose,
  loadMore,
  itemKey,
  id,
}: DrugFilterItemProps): JSX.Element => {
  const theme = useTheme() as Theme;
  const [selected, setSelected] = useState(selectedDrug || {});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenTimer, setIsModalOpenTimer] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState<{
    x: number;
    y: number;
    width: number;
    height: number;
    numberOfItems: number;
    availableSpace: number;
  }>({
    x: 0,
    y: 0,
    width: 40,
    height: 40,
    numberOfItems: 1,
    availableSpace: 500,
  });

  useEffect(() => {
    setSelected(selectedDrug);
  }, [selectedDrug]);

  useEffect(() => {
    if (isFilterModalShouldClose) {
      setIsModalOpen(false);
    }
  }, [isFilterModalShouldClose]);

  useEffect(() => {
    let timeout: any = null;
    if (isModalOpen) {
      setIsModalOpenTimer(isModalOpen);
    } else {
      timeout = setTimeout(() => setIsModalOpenTimer(isModalOpen), 300);
    }
    document.body.style.overflow = isModalOpen ? 'hidden' : 'auto';
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isModalOpen]);

  useEffect(() => {
    window.addEventListener('resize', handleLayout);
    handleLayout();
    return () => {
      window.removeEventListener('resize', handleLayout);
    };
  }, []);

  const handleLayout = () => {
    if (wrapperRef.current) {
      const layout = wrapperRef.current.getBoundingClientRect();
      const availableSpace = wrapperRef.current.parentElement.clientWidth;
      const numberOfItems = Math.floor(
        availableSpace / wrapperRef.current.offsetWidth,
      );
      setPosition({
        x: wrapperRef.current.offsetLeft,
        y: layout.y,
        width: wrapperRef.current.offsetWidth,
        height: wrapperRef.current.offsetHeight,
        numberOfItems,
        availableSpace,
      });
    }
  };
  const isLastInTheRow = () => {
    const modalSpace = position.numberOfItems * position.width;
    const totalPadding = modalSpace - position.availableSpace;
    const startPosition = -totalPadding / 2 - position.x;

    return (
      Math.ceil(
        (Math.abs(startPosition) + position.width / 2) / position.width,
      ) === position.numberOfItems
    );
  };

  const _renderFilterItem = (item: DrugItemType, index: number) => {
    const isSelected = selected[name || '']?.id === item?.id;
    return (
      <FilterItemContainer
        key={index}
        isSelected={isSelected}
        isLeft={index % 2 === 0}>
        <FilterItem
          whileTap={{
            opacity: 0.9,
            scale: 0.9,
            shadow: `0 8px 16px ${theme.shadow}`,
          }}
          onClick={() => {
            if (name) {
              setSelected({[name]: item});
            }
          }}>
          <FilterItemLabel isSelected={isSelected}>
            {item?.name || ''}
          </FilterItemLabel>
        </FilterItem>
      </FilterItemContainer>
    );
  };

  const submit = () => {
    onConfirmSelection && onConfirmSelection(selected);
    setIsModalOpen(false);
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const {scrollHeight, scrollTop, clientHeight} = e.currentTarget;
    if (
      scrollHeight - scrollTop <= clientHeight * 1.5 &&
      !isLoading &&
      !isLastPage &&
      !isError
    ) {
      loadMore();
    }
  };

  return (
    <Wrapper style={{zIndex: isModalOpen ? 2 : 1}} ref={wrapperRef}>
      <Container
        whileTap={{
          opacity: 0.9,
          scale: 0.9,
          shadow: `0 8px 16px ${theme.shadow}`,
        }}
        onClick={() => {
          setIsModalOpen(prev => !prev);
          onPress && onPress(itemKey, id);
        }}
        style={style}>
        <ContentContainer isSelected={selected[name || '']}>
          {isSkeleton ? (
            <Skeleton
              baseColor={'transparent'}
              highlightColor={theme.text.light + '55'}
              height={'100%'}
              width={'100%'}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                borderRadius: 100,
              }}
            />
          ) : (
            <Label isModalOpen={isModalOpen || selected[name || '']}>
              {name || ''}
            </Label>
          )}
        </ContentContainer>
      </Container>
      {isModalOpenTimer && (
        <>
          <Overlay
            isModalOpen={isModalOpen}
            variants={zoomInOut}
            initial={'zoomOut'}
            animate={isModalOpen ? 'zoomIn' : 'zoomOut'}
            transition={{
              duration: 0.3,
            }}
            onClick={() => setIsModalOpen(false)}
          />
          <ModalContainer
            isModalOpen={isModalOpen}
            variants={fadeInOut}
            initial={'fadeOut'}
            animate={isModalOpen ? 'fadeIn' : 'fadeOut'}
            transition={{
              duration: 0.3,
              ease: 'easeInOut',
            }}
            numberOfItems={position.numberOfItems}
            positionWidth={position.width}
            positionHeight={position.height}
            positionX={position.x}
            positionY={position.y}
            availableSpace={position.availableSpace}>
            <MaskContainer {...position}>
              {position?.x > position?.width / 2 && (
                <MaskIconContainer position={position} isLeft>
                  <Icon
                    type={'SVG'}
                    name={'subtract'}
                    size={0.4 * (position?.width || 0)}
                    color={theme.homeBackground}
                  />
                </MaskIconContainer>
              )}
              {!isLastInTheRow() && (
                <MaskIconContainer position={position}>
                  <Icon
                    type={'SVG'}
                    name={'subtract'}
                    size={0.4 * (position?.width || 0)}
                    color={theme.homeBackground}
                  />
                </MaskIconContainer>
              )}
            </MaskContainer>
            <ModalContentContainer
              isFirst={position?.x < position?.width / 2}
              isLast={isLastInTheRow()}>
              <FilterContainer
                onScroll={handleScroll}
                flex={!(!isLoading && !isError && (data || []).length === 0)}>
                {!isError &&
                  data?.length > 0 &&
                  (data || []).map(_renderFilterItem)}
                {isLoading && !isError && (data || []).length === 0 && (
                  <DrugFilterSkeleton />
                )}
                <AnimatedDiv
                  isZoomedIn={
                    !isLoading && !isError && (data || []).length === 0
                  }>
                  <BlurContainer
                    style={{
                      minWidth: '30%',
                      aspectRatio: 1,
                    }}>
                    <Icon
                      name={'empty'}
                      type={'SVG'}
                      color={theme.text.light}
                      size={theme.text.s3}
                    />
                    <EmptyText>{tr('drugs.empty')}</EmptyText>
                  </BlurContainer>
                </AnimatedDiv>
                <AnimatedDiv isZoomedIn={isError}>
                  <BlurContainer style={{minWidth: '30%', aspectRatio: 1}}>
                    <ErrorView onRefresh={() => loadMore(true)} />
                  </BlurContainer>
                </AnimatedDiv>
                <AnimatedDiv
                  style={{
                    display: data?.length > 0 ? 'flex' : 'none',
                    marginTop: '1rem',
                  }}
                  isZoomedIn={
                    isLoading && !isError && !isLastPage && data?.length > 0
                  }>
                  <BlurContainer>
                    <Icon
                      name={'loader'}
                      type={'SVG'}
                      color={theme.text.light}
                      size={theme.text.s4}
                    />
                  </BlurContainer>
                </AnimatedDiv>
              </FilterContainer>
              <BottomContainer>
                <ConfirmSelection
                  onPress={submit}
                  disabled={
                    !selected[name || ''] ||
                    selected[name || '']?.id === selectedDrug[name || '']?.id
                  }
                  dynamicWidth
                  outline
                  isLabelGradient
                  radius={100}
                  labelStyle={{
                    paddingLeft: theme.text.s6,
                  }}
                  style={{
                    opacity:
                      !selected[name || ''] ||
                      selected[name || '']?.id === selectedDrug[name || '']?.id
                        ? 0.4
                        : 1,
                  }}
                  isSmall
                  label={tr('drugs.confirmSelection')}
                  showRightIcon
                  rightIconName={'arrowDown'}
                  rightIconType={'SVG'}
                  rightIconSize={theme.text.s55}
                  rightIconContainerStyle={{transform: 'rotate(-90deg)'}}
                />
              </BottomContainer>
            </ModalContentContainer>
          </ModalContainer>
        </>
      )}
    </Wrapper>
  );
};

export default memo(DrugFilterItem);

const diagnosticToolsDermatology =
  process.env.REACT_APP_PDF_URL + 'dt/dermatology/';

export const diagnosticToolsDermatologyData = [
  {
    id: 0,
    title: 'Acne',
    pdf_link: diagnosticToolsDermatology + 'Acne_Diagnosis.pdf',
  },
  {
    id: 1,
    title: 'Anogenital Warts',
    pdf_link: diagnosticToolsDermatology + 'Anogenital_Warts_Diagnosis.pdf',
  },
  {
    id: 2,
    title: 'Dermatitis and Urticaria',
    pdf_link:
      diagnosticToolsDermatology + 'Dermatitis_and_Urticaria_Diagnosis.pdf',
  },
  {
    id: 3,
    title: 'Lupus',
    pdf_link: diagnosticToolsDermatology + 'Lupus_Diagnosis.pdf',
  },
  {
    id: 4,
    title: 'Psoriasis',
    pdf_link: diagnosticToolsDermatology + 'Psoriasis_Diagnosis.pdf',
  },
  {
    id: 5,
    title: 'Rosacea',
    pdf_link: diagnosticToolsDermatology + 'Rosacea_Diagnosis.pdf',
  },
  {
    id: 6,
    title: 'Skin Cancer',
    pdf_link: diagnosticToolsDermatology + 'Skin_Cancer_Diagnosis.pdf',
  },
  {
    id: 7,
    title: 'Tinea Infection',
    pdf_link: diagnosticToolsDermatology + 'TINEA_INFECTION_DIAGNOSIS.pdf',
  },
  {
    id: 8,
    title: 'Varicella-Zoster Virus Infection',
    pdf_link:
      diagnosticToolsDermatology +
      'Varicella_Zoster_Virus_Infection_Diagnosis.pdf',
  },
  {
    id: 9,
    title: 'Vitiligo',
    pdf_link: diagnosticToolsDermatology + 'Vitiligo_Diagnosis.pdf',
  },
];

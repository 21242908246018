const diagnosticToolsNeurology =
  process.env.REACT_APP_PDF_URL + 'dt/neurology/';

export const diagnosticToolsNeurologyData = [
  {
    id: 0,
    title: 'Autism Spectrum Disorder',
    pdf_link:
      diagnosticToolsNeurology + 'Autism_Spectrum_Disorder_Diagnostic_Tool.pdf',
  },
  {
    id: 1,
    title: 'Brain Tumor',
    pdf_link: diagnosticToolsNeurology + 'Brain_Tumor_Diagnostic_Tool.pdf',
  },
  {
    id: 2,
    title: 'Epilepsy',
    pdf_link: diagnosticToolsNeurology + 'Epilepsy_Diagnostic_Tool.pdf',
  },
  {
    id: 3,
    title: 'Dementia',
    pdf_link: diagnosticToolsNeurology + 'Dementia_Diagnostic_Tool.pdf',
  },
  {
    id: 4,
    title: 'Guillain-Barre Syndrome',
    pdf_link:
      diagnosticToolsNeurology + 'Guillain_Barre_Syndrome_Diagnostic_Tool.pdf',
  },
  {
    id: 5,
    title: 'Headaches',
    pdf_link: diagnosticToolsNeurology + 'Headaches_Diagnostic_Tool.pdf',
  },
  {
    id: 6,
    title: 'Migraine',
    pdf_link: diagnosticToolsNeurology + 'Migraine_Diagnostic_Tool.pdf',
  },
  {
    id: 7,
    title: 'Multiple Sclerosis',
    pdf_link:
      diagnosticToolsNeurology + 'Multiple_Sclerosis_Diagnostic_Tool.pdf',
  },
  {
    id: 8,
    title: "Parkinson's Disease",
    pdf_link:
      diagnosticToolsNeurology + 'Parkinsons_Disease_Diagnostic_Tool.pdf',
  },
  {
    id: 9,
    title: 'Peripheral Neuropathy',
    pdf_link:
      diagnosticToolsNeurology + 'Peripheral_Neuropathy_Diagnostic_Tool.pdf',
  },
];

const guidelinesNeurology = process.env.REACT_APP_PDF_URL + 'gl/neurology/';

export const guidelinesNeurologyData = [
  {
    id: 0,
    title: 'Autism Spectrum Disorder',
    pdf_link: guidelinesNeurology + 'Autism_Spectrum_Disorder_Guidelines.pdf',
  },
  {
    id: 1,
    title: 'Brain Tumor',
    pdf_link: guidelinesNeurology + 'Brain_Tumor_Guidelines.pdf',
  },
  {
    id: 2,
    title: 'Epilepsy',
    pdf_link: guidelinesNeurology + 'Epilepsy_Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Dementia',
    pdf_link: guidelinesNeurology + 'Dementia_Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Guillain-Barre Syndrome',
    pdf_link: guidelinesNeurology + 'Guillain_Barre_Syndrome_Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Headaches',
    pdf_link: guidelinesNeurology + 'Headaches_Guidelines.pdf',
  },
  {
    id: 6,
    title: 'Migraine',
    pdf_link: guidelinesNeurology + 'Migraine_Guidelines.pdf',
  },
  {
    id: 7,
    title: 'Multiple Sclerosis',
    pdf_link: guidelinesNeurology + 'Multiple_Sclerosis_Guidelines.pdf',
  },
  {
    id: 8,
    title: "Parkinson's Disease",
    pdf_link: guidelinesNeurology + 'Parkinsons_Disease_Guidelines.pdf',
  },
  {
    id: 9,
    title: 'Peripheral Neuropathy',
    pdf_link: guidelinesNeurology + 'Peripheral_Neuropathy_Guidelines.pdf',
  },
];

import React, {useEffect, useRef, useState} from 'react';
import styled, {useTheme} from 'styled-components';
import {motion} from 'framer-motion';
import {AnswerItemProps} from '../../interfaces/components/answerItem';
import {Theme} from '../../theme/lightTheme';
import Icon from '../icon';

const Container = styled(motion.div)<{isReferences: boolean}>`
  width: 90%;
  align-self: center;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${({theme, isReferences}) =>
    isReferences ? 'transparent' : theme.homeBackground};
  border-radius: 20px;
  overflow: hidden;
  background: ${({isReferences, theme}) =>
    isReferences
      ? `linear-gradient(45deg, ${theme.primary + 'BB'}, ${theme.secondary + 'BB'})`
      : `linear-gradient(45deg, ${theme.voiceQuestionInput.background + '55'}, ${theme.voiceQuestionInput.background + 'AA'})`};
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  box-shadow: 0 8px 16px ${({theme}) => theme.shadow};
  backdrop-filter: blur(6px);
`;

const AnswerContentContainer = styled.div<{isReferences: boolean}>`
  width: 100%;
  height: 100%;
  position: relative;
`;
const CloseAnswerIconContainer = styled(motion.button)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 99;
  border: none;
  background: none;
  height: 3rem;
  width: 3rem;
  border-radius: 100px;
`;
const AnswerContainer = styled(motion.button)<{
  isAnswerOpen: boolean;
  isReferences: boolean;
  arrowItemHeight: number;
}>`
  width: 100%;
  min-height: ${({arrowItemHeight}) => arrowItemHeight}px;
  position: ${({isAnswerOpen}) => (isAnswerOpen ? 'relative' : 'absolute')};
  bottom: 0;
  backdrop-filter: blur(4px);
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  background: ${({isReferences, theme}) =>
    isReferences
      ? `linear-gradient(to top, ${theme.secondary + '44'}, ${theme.primary + '44'})`
      : `${theme.voiceQuestionInput.background + '77'}`};
`;
const AnswerIconAnimatedContainer = styled(motion.div)`
  margin: auto 0;
`;
const ContentView = styled.div<{isReferences: boolean}>`
  padding: ${({theme}) => `calc(${theme.text.s4})`};
  color: ${({theme, isReferences}) =>
    isReferences ? theme.text.light : theme.text.dark};
  font-size: ${({theme}) => theme.text.s7};
  line-height: ${({theme}) => `calc(${theme.text.s7}  * 1.4)`};
`;

const AccordionItem = styled(motion.div).attrs({
  initial: 'closed',
})`
  overflow: hidden;
  padding-right: 1.3rem;
`;
/**
 * AnswerItem component.
 *
 * @param style
 * @param {AnswerItemProps} props - Props including style customizations and additional TextInput properties.
 * @returns {JSX.Element} The stylized QuestionInput component with conditional theming and localized placeholders.
 */
const AnswerItem: React.FC<AnswerItemProps> = ({
  style,
  className,
  content,
  onClosePressed,
  isReferences,
}: AnswerItemProps): JSX.Element => {
  const theme = useTheme() as Theme;
  const minItemHeight = theme.dimensions.hdp(25);
  const arrowItemHeight = theme.dimensions.hdp(5);
  const [isOpen, setIsOpen] = useState(false);
  const [isExpandable, setExpandable] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  // Calculate height before first render using useEffect
  useEffect(() => {
    if (
      contentRef.current &&
      contentRef.current.scrollHeight > minItemHeight - arrowItemHeight - 10
    ) {
      setExpandable(true);
    } else {
      setExpandable(false);
    }
  }, [theme, minItemHeight, arrowItemHeight, contentRef]);

  const accordionVariants = {
    closed: {
      height: minItemHeight,
      transition: {
        type: 'tween',
        duration: 0.3,
      },
    },
    open: isExpandable
      ? {
          height: 'auto',
          transition: {
            type: 'tween',
            duration: 0.3,
          },
        }
      : {
          height: minItemHeight,
        },
  };

  useEffect(() => {
    setIsOpen(false);
  }, [content]);

  const onAnswerPress = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <Container style={style} isReferences={isReferences} className={className}>
      <AnswerContentContainer isReferences={isReferences}>
        {onClosePressed && (
          <CloseAnswerIconContainer
            onClick={onClosePressed}
            whileTap={{
              backgroundColor:
                (isReferences ? theme.text.light : theme.text.grey) + '33',
              scale: 0.95,
            }}>
            <Icon
              name={'close'}
              size={theme.text.s7}
              color={isReferences ? theme.text.light : theme.text.grey}
            />
          </CloseAnswerIconContainer>
        )}
        <AccordionItem
          animate={isOpen ? 'open' : 'closed'}
          variants={accordionVariants}>
          <ContentView
            ref={contentRef}
            isReferences={isReferences}
            dangerouslySetInnerHTML={{__html: content}}
          />
        </AccordionItem>

        {isExpandable && (
          <AnswerContainer
            arrowItemHeight={arrowItemHeight}
            whileTap={{opacity: 0.2}}
            onClick={onAnswerPress}
            isReferences={isReferences}
            isAnswerOpen={isOpen}>
            <AnswerIconAnimatedContainer
              animate={{
                y: [-arrowItemHeight / 5, arrowItemHeight / 5],
              }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatType: 'mirror',
              }}>
              <motion.div
                animate={{
                  rotate: isOpen ? '180deg' : '0',
                }}>
                <Icon
                  name={'arrowDown'}
                  color={isReferences ? theme.text.light : theme.text.grey}
                  size={arrowItemHeight / 1.8}
                />
              </motion.div>
            </AnswerIconAnimatedContainer>
          </AnswerContainer>
        )}
      </AnswerContentContainer>
    </Container>
  );
};

export default AnswerItem;

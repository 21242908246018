const guidelinesPediatrics = process.env.REACT_APP_PDF_URL + 'gl/pediatrics/';

export const guidelinesPediatricsData = [
  {
    id: 0,
    title: 'Asthma',
    pdf_link: guidelinesPediatrics + 'Asthma_Guidelines.pdf',
  },
  {
    id: 1,
    title: 'Gastroenteritis and Constipation in Pediatrics',
    pdf_link:
      guidelinesPediatrics +
      'Gastroenteritis_Constipation_Pediatrics_Guidelines.pdf',
  },
  {
    id: 2,
    title: 'Croup',
    pdf_link: guidelinesPediatrics + 'Croup_Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Influenza',
    pdf_link: guidelinesPediatrics + 'Influenza_Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Measles Mumps Rubella Diagnosis',
    pdf_link: guidelinesPediatrics + 'MMR_Diagnosis_Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Pediatric Allergic Rhinitis',
    pdf_link:
      guidelinesPediatrics + 'Pediatric_Allergic_Rhinitis_Guidelines.pdf',
  },
  {
    id: 6,
    title: 'Pediatric Hepatitis Guidelines',
    pdf_link: guidelinesPediatrics + 'Pediatric_Hepatitis_Guidelines.pdf',
  },
  {
    id: 7,
    title: 'Pediatric Pneumonia',
    pdf_link: guidelinesPediatrics + 'Pediatric_Pneumonia_Guidelines.pdf',
  },
  {
    id: 8,
    title: 'Pediatric Urinary Tract Infection',
    pdf_link: guidelinesPediatrics + 'Pediatric_UTI_Guidelines.pdf',
  },
];

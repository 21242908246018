import * as React from 'react';

const SvgComponent = props => (
  <svg viewBox={'0 0 100 125'} {...props} fill={'none'} stroke={'none'}>
    <path
      fill={props.fill || '#000'}
      d="M67.7 99.3c-15.9 0-28.8-12.9-28.8-28.8s12.9-28.8 28.8-28.8 28.8 12.9 28.8 28.8-12.9 28.8-28.8 28.8zm0-54.1c-13.9 0-25.3 11.3-25.3 25.3 0 13.9 11.3 25.3 25.3 25.3C81.6 95.7 93 84.4 93 70.4c0-13.9-11.4-25.2-25.3-25.2z"
    />
    <path
      fill={props.fill || '#000'}
      d="M42.4 99.3H6.7c-1 0-1.9-.8-1.9-1.9V2.7c0-1 .8-1.9 1.9-1.9h60c.5 0 1 .2 1.3.5l17.3 17c.4.4.6.8.6 1.4V37c0 1-.8 1.9-1.9 1.9-1 0-1.9-.8-1.9-1.9V20.5L65.9 4.6H8.6v90.8h33.8c1 0 1.9.8 1.9 1.9 0 1.1-.8 2-1.9 2z"
    />
    <path
      fill={props.fill || '#000'}
      d="M19.8 33.3h40.1c1.2 0 2.2-1 2.2-2.2 0-1.2-1-2.2-2.2-2.2H19.8c-1.2 0-2.2 1-2.2 2.2 0 1.3 1 2.2 2.2 2.2zM20 21.2h40.1c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2H20c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2zM20 45.7h23.4c1.2 0 2.2-1 2.2-2.2s-1-2.2-2.2-2.2H20c-1.2 0-2.2 1-2.2 2.2s1 2.2 2.2 2.2zM34.5 58.5H19.9c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2h14.6c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2zM81.7 81.5c0-.1-.9-10.6-14.2-10.6-13.5 0-14.2 10.6-14.2 10.7v3.1c0 .9.7 1.6 1.6 1.6h25.2c.9 0 1.6-.7 1.6-1.6v-3.2zM67.7 68.3c3.9 0 7-3.5 7-7.8s-3.2-7.8-7-7.8c-3.9 0-7 3.5-7 7.8s3.1 7.8 7 7.8z"
    />
  </svg>
);
export default SvgComponent;

import styled, {useTheme} from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React from 'react';
import {Theme} from '../../theme/lightTheme';
import {useSelector} from 'react-redux';
import {RootState} from '../../redux/store';

const Container = styled.div<{isSummary: boolean}>`
  width: 100%;
  padding: 1rem;
  border: 1px solid
    ${({theme, isSummary}) => (isSummary ? 'transparent' : theme.text.light)};
  border-radius: 8px;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TableItemViewContainer = styled.div<any>`
  display: flex;
  flex-direction: ${({isLanguageRTL}) =>
    isLanguageRTL ? 'row-reverse' : 'row'};
  border-bottom: ${({noLine}) => (noLine ? 'none' : '1px solid #fff')};
  padding: 1.2rem 0;
  align-items: center;
  position: relative;
`;

const BoldTextSkeleton = styled.div<any>`
  width: 20%;
  margin-right: ${({fontSize}) => fontSize * 0.9}px;
  height: ${({fontSize}) => fontSize * 1.4 + 2}px;
  align-self: flex-start;
  text-align: center;
  position: relative;
  background: ${({theme}) => theme.skeletonBackground + '22'};
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  backdrop-filter: blur(2px);
  border-radius: 12px;
  box-shadow: 0 1px 1px ${({theme}) => theme.shadow};
`;

const LabelTextSkeleton = styled.div<any>`
  width: ${({lineWidth}) => lineWidth}%;
  height: ${({font}) => font?.fontSize * 1.2}px;
  position: relative;
  background: ${({theme}) => theme.skeletonBackground + '22'};
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  backdrop-filter: blur(2px);
  border-radius: 12px;
  box-shadow: 0 1px 1px ${({theme}) => theme.shadow};
`;

const LinesContainer = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  position: relative;
  flex-wrap: nowrap;
`;

const list = [
  {lines: [85]},
  {lines: [80, 50]},
  {lines: [90, 70, 50]},
  {lines: [80, 40]},
  {lines: [80, 70, 40]},
  {lines: [90, 50]},
  {lines: [80, 20]},
  {lines: [90, 70, 50]},
  {lines: [80, 40]},
  {lines: [80, 70, 40]},
  {lines: [90, 50]},
];
const summaryList = [80, 70, 40, 0, 0, 90, 70, 50, 0, 0, 90, 70, 50];

const ArticleSkeleton = ({isSummary}: {isSummary: boolean}) => {
  const theme = useTheme() as Theme;
  const userStatus = useSelector((state: RootState) => state?.auth);
  const {font} = userStatus?.config || {};

  const _renderLines = (item: any, index: number) => {
    return (
      <LabelTextSkeleton
        key={index}
        font={font}
        lineWidth={item}
        style={{
          marginTop: index == 0 ? 0 : font.fontSize / 1.4,
        }}>
        <Skeleton
          baseColor={'transparent'}
          highlightColor={theme.skeletonBackground}
          height={'100%'}
          width={'100%'}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            flex: 1,
            width: '100%',
            borderRadius: 1000,
            zIndex: 22,
          }}
        />
      </LabelTextSkeleton>
    );
  };

  const _renderTableRow = (item: any, index: number) => {
    return (
      <TableItemViewContainer key={index} noLine={index === list.length - 1}>
        <BoldTextSkeleton fontSize={font.fontSize}>
          <Skeleton
            baseColor={'transparent'}
            highlightColor={theme.skeletonBackground}
            height={'100%'}
            width={'100%'}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              flex: 1,
              width: '100%',
              borderRadius: 1000,
              zIndex: 22,
            }}
          />
        </BoldTextSkeleton>
        <LinesContainer>{item.lines.map(_renderLines)}</LinesContainer>
      </TableItemViewContainer>
    );
  };

  return (
    <Container isSummary={isSummary}>
      {isSummary ? summaryList.map(_renderLines) : list.map(_renderTableRow)}
    </Container>
  );
};

export default ArticleSkeleton;

const diagnosticToolsPediatrics =
  process.env.REACT_APP_PDF_URL + 'dt/pediatrics/';

export const diagnosticToolsPediatricsData = [
  {
    id: 0,
    title: 'Asthma',
    pdf_link: diagnosticToolsPediatrics + 'Asthma_Diagnostic_Tool.pdf',
  },
  {
    id: 1,
    title: 'Gastroenteritis and Constipation in Pediatrics',
    pdf_link:
      diagnosticToolsPediatrics +
      'Gastroenteritis_Constipation_Pediatrics_Diagnostic_Tool.pdf',
  },
  {
    id: 2,
    title: 'Croup',
    pdf_link: diagnosticToolsPediatrics + 'Croup_Diagnostic_Tool.pdf',
  },
  {
    id: 3,
    title: 'Influenza',
    pdf_link: diagnosticToolsPediatrics + 'Influenza_Diagnostic_Tool.pdf',
  },
  {
    id: 4,
    title: 'Measles Mumps Rubella Diagnosis',
    pdf_link: diagnosticToolsPediatrics + 'MMR_Diagnosis_Diagnostic_Tool.pdf',
  },
  {
    id: 5,
    title: 'Pediatric Allergic Rhinitis',
    pdf_link:
      diagnosticToolsPediatrics +
      'Pediatric_Allergic_Rhinitis_Diagnostic_Tool.pdf',
  },
  {
    id: 6,
    title: 'Pediatric Hepatitis Guidelines',
    pdf_link: diagnosticToolsPediatrics + 'Pediatric_Hepatitis_Guidelines.pdf',
  },
  {
    id: 7,
    title: 'Pediatric Pneumonia',
    pdf_link:
      diagnosticToolsPediatrics + 'Pediatric_Pneumonia_Diagnostic_Tool.pdf',
  },
  {
    id: 8,
    title: 'Pediatric Urinary Tract Infection',
    pdf_link: diagnosticToolsPediatrics + 'Pediatric_UTI_Diagnostic_Tool.pdf',
  },
];

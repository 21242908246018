import React from 'react';
import styled, {useTheme} from 'styled-components';
import myPressable from '../myPressable';
import {CircleTabItemProps} from '../../interfaces/components/circleTabItem';
import Icon from '../icon';
import {Theme} from '../../theme/lightTheme';

const Container = styled(myPressable)`
  width: auto;
  position: relative;
  background-size: cover;
  background-position: center;
  border: 2px solid ${({theme}) => theme.primary};
  padding: 0;
  box-shadow: 5px 5px 16px ${({theme}) => theme.shadow};
`;

const GridContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to bottom,
    ${({theme}) => theme.primary + 'CC'},
    ${({theme}) => theme.secondary + 'CC'}
  );
  backdrop-filter: blur(2px);
  overflow: hidden;
`;
const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
`;

const Title = styled.p`
  font-size: ${({theme}) => theme.text.s7};
  font-weight: bolder;
  color: ${({theme}) => theme.text.light};
  text-align: center;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);
  flex-grow: 0;
  white-space: nowrap;
  padding: ${({theme}) => theme.text.s11} ${({theme}) => theme.text.s7};
`;
const RightIconContainer = styled.div<any>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({size}) => size};
`;
const Chip = ({
  label,
  labelStyle,
  style,
  containerStyle,
  onPress,
  borderRadius = 1000,
  rightIconName,
  rightIconType,
  onRightIconPress,
  rightIconSize,
  rightIconColor,
}: CircleTabItemProps): JSX.Element => {
  const theme = useTheme() as Theme;
  return (
    <Container
      containerStyle={{
        overflow: 'visible',
        borderWidth: 1,
        ...(containerStyle || {}),
      }}
      style={{
        borderRadius,
        ...(style || {}),
      }}
      onClick={onPress}>
      <GridContainer style={{borderRadius}}>
        <ContentContainer>
          <Title style={labelStyle}>
            {typeof label === 'function' ? label() : label || ''}
          </Title>
        </ContentContainer>
        {rightIconName && rightIconType && (
          <RightIconContainer size={rightIconSize || theme.text.s6}>
            <Icon
              type={rightIconType}
              role={onRightIconPress ? 'button' : ''}
              onPress={onRightIconPress}
              name={rightIconName}
              size={rightIconSize || theme.text.s8}
              color={rightIconColor || theme.text.light}
            />
          </RightIconContainer>
        )}
      </GridContainer>
    </Container>
  );
};

export default Chip;

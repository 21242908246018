import {getUrlParts, isUrl} from './helper';
import {initialFontReducer} from '../assets/static/fontChanger';
import {marked} from 'marked';
import parse from 'html-react-parser';
import React from 'react';

export interface ArticleOptionProps {
  isRTL?: boolean;
  color?: string;
  fontSize?: number;
  fontWeight?: string;
}

/**
 * Wraps valid URLs in the provided text with <a> tags.
 *
 * @param {string} text - Input text containing URLs.
 * @param {Object} options - Options for anchor tag generation.
 * @param {string} options.target - Target attribute for the anchor tag (default "_blank").
 * @returns {string} HTML string with URLs wrapped in <a> tags.
 */
export const wrapUrls = (
  text: string,
  options?: ArticleOptionProps,
): string => {
  const {
    isRTL = false,
    color = 'white',
    fontSize = initialFontReducer.fontSize,
  } = options || {};
  const parts = getUrlParts(text);
  return parts
    .map(part => {
      if (isUrl(part)) {
        const href = part.startsWith('www.') ? `https://${part}` : part;
        return `<a style="font-size: ${fontSize + 1}px;font-weight: bold;color: ${color};direction: ${isRTL ? 'rtl' : 'ltr'};" href="${href}" target="${'_blank'}" rel="noopener noreferrer">${part}</a>`;
      }
      return part;
    })
    .join('');
};

/**
 * Determines whether a string contains valid HTML.
 *
 * @param {string} content - The input string to check.
 * @returns {boolean} True if the content contains valid HTML; otherwise, false.
 */
export const isHtml = (content: string): boolean => {
  if (!content || typeof content !== 'string') {
    return false;
  }

  // Check if the content contains opening and closing HTML tags
  const htmlPattern = /<([a-z][\w-]*)\b[^>]*>(.*?)<\/\1>/i; // Matches full opening/closing tags
  const selfClosingPattern = /<([a-z][\w-]*)\b[^>]*\/>/i; // Matches self-closing tags (e.g., <img />)

  return htmlPattern.test(content) || selfClosingPattern.test(content);
};

/**
 * Determines if the content is in Markdown format.
 *
 * @param {string | any} content - The content string to check.
 * @returns {boolean} True if the content appears to be Markdown; otherwise, false.
 */
export const isMarkdown = (content: any): boolean => {
  if (typeof content !== 'string') return false;

  const markdownPatterns = [
    /^#{1,6}\s/, // Markdown headings (#, ##, ###, etc.)
    /^\s*[-+*]\s+/, // Unordered lists (-, +, or *)
    /^\s*\d+\.\s+/, // Ordered lists (1., 2., etc.)
    /\*\*(.*?)\*\*/, // Bold (**bold**)
    /\*(.*?)\*/, // Italic (*italic*)
    /`([^`]+)`/, // Inline code (`code`)
    /^>\s+/, // Blockquotes (>)
    /!$begin:math:display$.*?$end:math:display$$begin:math:text$.*?$end:math:text$/, // Images (![alt text](url))
    /$begin:math:display$.*?$end:math:display$$begin:math:text$.*?$end:math:text$/, // Links ([text](url))
  ];

  return markdownPatterns.some(pattern => pattern.test(content));
};

/**
 * Determines if the content is plain text (neither Markdown nor HTML).
 *
 * @param {string} content - The content string to check.
 * @returns {boolean} True if the content is plain text; otherwise, false.
 */
export const isPlainText = (content: string): boolean => {
  return !isMarkdown(content) && !isHtml(content);
};

/**
 * Processes content that might contain HTML, Markdown, or plain text.
 *
 * @param {string | any} content - Mixed content string.
 * @param options
 * @returns {string} Processed HTML.
 */
export const processContent = (
  content: any,
  options?: ArticleOptionProps,
): string => {
  if (typeof content !== 'string') {
    content = String(content); // Ensure content is a string
  }

  let processedContent = '';

  // Detect if content is Markdown or HTML
  if (isHtml(content)) {
    processedContent = wrapUrls(content, options);
  } else if (isMarkdown(content)) {
    //DO NOT EDIT THIS TO HANDLE LINE BREAK WITH ENTER CHAR
    const normalizeLineBreaks = (content || '').replace(
      /\n/g,
      `<br>

`,
    );
    processedContent = marked.parse(normalizeLineBreaks, {
      async: false,
    });
  } else {
    processedContent = wrapUrls(content, options);
  }

  return processedContent;
};
/**
 * Sanitizes HTML content by applying CSS transformations.
 * - Adjusts font size and color.
 *
 * @param {string} html - The HTML string to sanitize.
 * @param {Object} options - Configuration options for sanitization.
 * @param {string} options.color - Text color to apply.
 * @param {number} options.fontSize - Font size to apply.
 * @returns {string} The sanitized HTML.
 */
export const sanitizeHtml = (
  html: string,
  options?: ArticleOptionProps,
): string => {
  const {
    isRTL = false,
    color = 'white',
    fontSize = initialFontReducer.fontSize,
    fontWeight = initialFontReducer.fontWeight,
  } = options || {};

  return `
    <div style="font-size: ${fontSize}px;line-height: ${fontSize * 1.5}px;font-weight: ${fontWeight};color: ${color};direction: ${isRTL ? 'rtl' : 'ltr'};">
        ${html}
      </div>
  `;
};

/**
 * Main function to handle and render content appropriately.
 * Parses the content and applies sanitization, ready for rendering in React.
 *
 * @param {string} content - The content string to render.
 * @param {Object} options - Configuration options for rendering.
 * @param {boolean} options.isRTL - If true, applies right-to-left text direction.
 * @param {string} options.color - Text color to apply.
 * @param {number} options.fontSize - Font size to apply.
 * @returns {React.ReactNode} Rendered and processed content as React elements.
 */
export const renderArticleContent = (
  content: string,
  options?: ArticleOptionProps,
): React.ReactNode => {
  const processedContent = processContent(content, options);
  const sanitizedContent = sanitizeHtml(processedContent, options);
  return parse(sanitizedContent);
};

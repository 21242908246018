import styled, {css, useTheme} from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import React from 'react';
import {Theme} from '../../theme/lightTheme';
import {motion} from 'framer-motion';
import {isSmallHeight} from '../../utils/responsive';

const SkeletonWrapper = styled.div<{isLeft: boolean}>`
  border-color: ${({theme}) => theme.primary};
  background-color: ${({theme}) => theme.text.grey + '11'};
  border-bottom-color: ${({theme}) => theme.text.grey + '22'};
  border-bottom-style: solid;
  display: flex;
  position: relative;
  flex: 1 1 calc(50% - 1px);
  align-items: center;
  ${({isLeft, theme}) =>
    !isLeft &&
    css`
      border-left-width: 1px;
      border-left-color: ${theme.primary};
      border-left-style: solid;
    `};
`;
const FilterItem = styled(motion.button)`
  padding: ${isSmallHeight ? 1 : 1.5}rem ${isSmallHeight ? 0.5 : 1}rem;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: transparent;
`;
const DrugFilterSkeleton = ({className}: {className?: string}) => {
  const theme = useTheme() as Theme;
  return Array(30)
    .fill(0)
    .map((i, index) => (
      <SkeletonWrapper
        isLeft={index % 2 === 0}
        className={className}
        key={index}>
        <Skeleton
          baseColor={'transparent'}
          highlightColor={theme.text.grey + '22'}
          height={'100%'}
          width={'100%'}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        />
        <FilterItem>skeleton</FilterItem>
      </SkeletonWrapper>
    ));
};

export default DrugFilterSkeleton;

import './style.css';

import React, {cloneElement, memo, useCallback, useState} from 'react';

import {Backdrop} from './components/backdrop';
import {IconList} from './components/icon/list';
import {Portal} from './components/portal';
import {SocialIcons} from './components/social-icons';
import {useDisclosure} from './hooks/use-disclosure';
import {RWebShareProps, ShareData} from './interfaces';

const defaultSites = Object.keys(IconList).slice(0, 8);

export const RWebShare = memo((props: RWebShareProps) => {
  const {onOpen, onClose, isOpen} = useDisclosure();
  const [shareData, setShareData] = useState<ShareData>(null);

  const handleOnClick = useCallback(async () => {
    if (window.navigator.share && !props.disableNative) {
      try {
        let tempData: ShareData = shareData;
        if (props.generateData) {
          const res = await props.generateData();
          if (res) {
            tempData = {
              ...res,
              title: res.title || 'share',
              text: res.text || '',
              url:
                res.url ||
                (typeof window !== 'undefined' && window.location.href) ||
                '',
            };
          } else {
            tempData = null;
          }
        }
        if (tempData) {
          setShareData(tempData);
          await window.navigator.share(tempData);
          props.onClick();
        }
      } catch (e) {
        console.warn(e);
      }
    } else {
      onOpen();
    }
  }, [shareData]);

  return (
    <>
      {/* Overrides Children element's `onClick` event */}
      {cloneElement(props.children, {
        ...props.children?.props,
        onClick: handleOnClick,
      })}

      {/* Share Component */}
      {isOpen && shareData && (
        <Portal>
          <Backdrop onClose={onClose}>
            <SocialIcons
              onClose={onClose}
              sites={props.sites || defaultSites}
              data={shareData}
              closeText={props.closeText}
              onClick={props.onClick}
            />
          </Backdrop>
        </Portal>
      )}
    </>
  );
});

const diagnosticToolsEndocrinology =
  process.env.REACT_APP_PDF_URL + 'dt/endocrinology/';

export const diagnosticToolsEndocrinologyData = [
  {
    id: 0,
    title: 'Diabetes',
    pdf_link: diagnosticToolsEndocrinology + 'Diabetes_Diagnosis.pdf',
  },
  {
    id: 1,
    title: 'Thyroid Disorders',
    pdf_link: diagnosticToolsEndocrinology + 'Thyroid_disorders_diagnosis.pdf',
  },
  {
    id: 2,
    title: 'Adrenal Disorders',
    pdf_link: diagnosticToolsEndocrinology + 'Adrenal_Disorders_Diagnosis.pdf',
  },
  {
    id: 3,
    title: 'Pituitary Disorders',
    pdf_link:
      diagnosticToolsEndocrinology + 'Pituitary_Disorders_Diagnosis.pdf',
  },
  {
    id: 4,
    title: 'Parathyroid Disorders',
    pdf_link: diagnosticToolsEndocrinology + 'Parathyrpid_Diagnosis.pdf',
  },
  {
    id: 5,
    title: 'Gonadal Disorders',
    pdf_link:
      diagnosticToolsEndocrinology +
      'GONADAL_DISORDERS_DIAGNOSTIC_CRITERIA.pdf',
  },
  {
    id: 6,
    title: 'Metabolic Syndrome',
    pdf_link: diagnosticToolsEndocrinology + 'Metabolic_Disorder_Diagnosis.pdf',
  },
];

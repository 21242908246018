import {
  GenerateSharedLinkBody,
  NewsItemDetailsType,
  SharedLinkDetailsData,
} from '../../../interfaces/api/tabs';
import {services} from '../../../api';
import {
  finishLoading,
  startLoading,
} from '../../../connected-components/loading/actions';

/**
 * this function to get News Details list
 *
 * @return {NewsItemDetailsType | null} news details
 */
export const getNewsDetails = async (
  id: number,
): Promise<NewsItemDetailsType | null> => {
  try {
    const res = await services.news.getNewsDetails(id);
    if (Array.isArray(res?.data)) {
      return res?.data.length > 0 ? res?.data[0] : null;
    }
    return res?.data;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get News Details FAIL: ', error);
    }
    return null;
  }
};

/**
 * this function to generate shared News link
 *
 * @return {string | null} shared link to news detail
 */
export const generateShareLink = async (
  body: GenerateSharedLinkBody,
): Promise<string | null> => {
  try {
    startLoading();
    const res = await services.news.generateSharedLink(body);
    return res?.data?.link;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Generate Share Link FAIL: ', error);
    }
    return null;
  } finally {
    finishLoading();
  }
};

/**
 * this function to get Share Link News Details
 *
 * @return {SharedLinkDetailsData | null} shared link news detail date
 */
export const getShareLinkNewsDetails = async (
  token: string,
): Promise<SharedLinkDetailsData | null> => {
  try {
    startLoading();
    const res = await services.news.getSharedLinkDetails(token);
    return res?.data;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Share Link News Details FAIL: ', error);
    }
    return null;
  } finally {
    finishLoading();
  }
};

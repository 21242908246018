import React, {useState} from 'react';
import styled, {useTheme} from 'styled-components';
import {motion} from 'framer-motion';
import {Theme} from '../../theme/lightTheme';

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipText = styled(motion.p)<{isVisible: boolean}>`
  visibility: ${({isVisible}) => (isVisible ? 'visible' : 'hidden')};
  background-color: ${({theme}) => theme.homeBackground};
  color: ${({theme}) => theme.text.title};
  padding: 1.5rem 1rem;
  border-radius: 10px;
  position: absolute;
  width: ${({theme}) =>
    theme.isMobile ? theme.dimensions.wdp(80) : theme.dimensions.wdp(50)}px;
  bottom: 130%;
  left: 5%;
  transform: translateX(-50%);
  font-size: 0.875rem;
  z-index: 99;
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 1.2rem;
    margin-left: -0.5rem;
    border-width: 0.5rem;
    border-style: solid;
    border-color: ${({theme}) => theme.homeBackground} transparent transparent
      transparent;
  }
`;

const Tooltip = ({
  children,
  text,
}: {
  children: React.ReactNode;
  text: string;
}) => {
  const theme = useTheme() as Theme;
  const [isVisible, setIsVisible] = useState(false);

  return (
    <TooltipContainer
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}>
      {children}
      <TooltipText
        isVisible={isVisible}
        initial={{opacity: 0, y: 10}}
        animate={{opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 10}}
        transition={{duration: 0.3, ease: 'easeInOut'}}>
        {text}
      </TooltipText>
    </TooltipContainer>
  );
};

export default Tooltip;

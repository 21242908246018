import {store} from '../../redux/store';
import {openFiltersModal, hideFiltersModal} from './reducer';
import {FiltersModalMessage} from '../../interfaces/connected-components/filtersModal';

export const showFiltersModal = ({
  onSubmit,
  filters,
  filtersValues,
}: FiltersModalMessage) => {
  store.dispatch(
    openFiltersModal({
      onSubmit,
      filters,
      filtersValues,
    }),
  );
};

/**
 * This function close Filters modal
 */
export const closeFiltersModal = () => {
  store.dispatch(hideFiltersModal());
};

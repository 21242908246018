const treatmentOptionsOphthalmology =
  process.env.REACT_APP_PDF_URL + 'to/ophthalmology/';

export const treatmentOptionsOphthalmologyData = [
  {
    id: 0,
    title: 'Glaucoma',
    // pdf_link: treatmentOptionsOphthalmology + 'Glaucoma_Treatment_Options.pdf',
    pdf_link: null,
  },
  {
    id: 1,
    title: 'Eye Infection',
    // pdf_link:
    //   treatmentOptionsOphthalmology + 'Eye_Infection_Treatment_Options.pdf',
  },
  {
    id: 2,
    title: 'Dry Eye Syndrome',
    pdf_link: null,
    // pdf_link:
    //   treatmentOptionsOphthalmology + 'Dry_Eye_Syndrome_Treatment_Options.pdf',
  },
  {
    id: 3,
    title: 'Diabetic Retinopathy',
    pdf_link: null,
    // pdf_link:
    //   treatmentOptionsOphthalmology + 'Diabetic_Retinopathy_Treatment_Options.pdf',
  },
  {
    id: 4,
    title: 'Refractive Errors',
    pdf_link: null,
    // pdf_link:
    //   treatmentOptionsOphthalmology + 'Refractive_Errors_Treatment_Options.pdf',
  },
  {
    id: 5,
    title: 'Age-related Macular Degeneration',
    pdf_link: null,
    // pdf_link:
    //   treatmentOptionsOphthalmology +
    //   'Age_Related_Macular_Degeneration_Treatment_Options.pdf',
  },
  {
    id: 6,
    title: 'Cataract',
    pdf_link: null,
    // pdf_link: treatmentOptionsOphthalmology + 'Cataract_Treatment_Options.pdf',
  },
];

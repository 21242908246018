const treatmentOptionsDermatology =
  process.env.REACT_APP_PDF_URL + 'to/dermatology/';

export const treatmentOptionsDermatologyData = [
  {
    id: 0,
    title: 'Acne',
    pdf_link: treatmentOptionsDermatology + 'Acne_Treatment_Options.pdf',
  },
  {
    id: 1,
    title: 'Anogenital Warts',
    pdf_link:
      treatmentOptionsDermatology + 'Anogenital_Warts_Treatment_Options.pdf',
  },
  {
    id: 2,
    title: 'Dermatitis and Urticaria',
    pdf_link:
      treatmentOptionsDermatology + 'Dermatitis_and_Urticaria_Treatment.pdf',
  },
  {
    id: 3,
    title: 'Lupus',
    pdf_link: treatmentOptionsDermatology + 'Lupus_Treatment.pdf',
  },
  {
    id: 4,
    title: 'Psoriasis',
    pdf_link: treatmentOptionsDermatology + 'Psoriasis_Treatment.pdf',
  },
  {
    id: 5,
    title: 'Rosacea',
    pdf_link: treatmentOptionsDermatology + 'Rosacea_Treatment Options.pdf',
  },
  {
    id: 6,
    title: 'Skin Cancer',
    pdf_link: treatmentOptionsDermatology + 'Skin_Cancer_Treatment.pdf',
  },
  {
    id: 7,
    title: 'Tinea Infection',
    pdf_link:
      treatmentOptionsDermatology + 'TINEA_INFECTION TREATMENT_OPTION.pdf',
  },
  {
    id: 8,
    title: 'Varicella-Zoster Virus Infection',
    pdf_link:
      treatmentOptionsDermatology +
      'Varicella_Zoster_Virus_Infection_TREATMENT_OPTIONS.pdf',
  },
  {
    id: 9,
    title: 'Vitiligo',
    pdf_link: treatmentOptionsDermatology + 'Vitiligo_Treatment_Options.pdf',
  },
];

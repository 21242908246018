const guidelinesOphthalmology =
  process.env.REACT_APP_PDF_URL + 'gt/ophthalmology/';

export const guidelinesOphthalmologyData = [
  {
    id: 0,
    title: 'Glaucoma',
    // pdf_link: guidelinesOphthalmology + 'Glaucoma_Guidelines.pdf',
    pdf_link: null,
  },
  {
    id: 1,
    title: 'Eye Infection',
    // pdf_link:
    //   guidelinesOphthalmology + 'Eye_Infection_Guidelines.pdf',
  },
  {
    id: 2,
    title: 'Dry Eye Syndrome',
    pdf_link: null,
    // pdf_link:
    //   guidelinesOphthalmology + 'Dry_Eye_Syndrome_Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Diabetic Retinopathy',
    pdf_link: null,
    // pdf_link:
    //   guidelinesOphthalmology + 'Diabetic_Retinopathy_Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Refractive Errors',
    pdf_link: null,
    // pdf_link:
    //   guidelinesOphthalmology + 'Refractive_Errors_Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Age-related Macular Degeneration',
    pdf_link: null,
    // pdf_link:
    //   guidelinesOphthalmology +
    //   'Age_Related_Macular_Degeneration_Guidelines.pdf',
  },
  {
    id: 6,
    title: 'Cataract',
    pdf_link: null,
    // pdf_link: guidelinesOphthalmology + 'Cataract_Guidelines.pdf',
  },
];

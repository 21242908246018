const guidelinesEndocrinology =
  process.env.REACT_APP_PDF_URL + 'gl/endocrinology/';

export const guidelinesEndocrinologyData = [
  {
    id: 0,
    title: 'Diabetes',
    pdf_link: guidelinesEndocrinology + 'Diabetes_Guidelines.pdf',
  },
  {
    id: 1,
    title: 'Thyroid Disorders',
    pdf_link: guidelinesEndocrinology + 'Thyroid_disorders_guidelines.pdf',
  },
  {
    id: 2,
    title: 'Adrenal Disorders',
    pdf_link: guidelinesEndocrinology + 'Adrenal_Disorders_Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Pituitary Disorders',
    pdf_link: guidelinesEndocrinology + 'Pituitary_Disorders_Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Parathyroid Disorders',
    pdf_link: guidelinesEndocrinology + 'Parathyroid_Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Gonadal Disorders',
    pdf_link: guidelinesEndocrinology + 'Gonadal_Disorders_Guidelines.pdf',
  },
  {
    id: 6,
    title: 'Metabolic Syndrome',
    pdf_link: guidelinesEndocrinology + 'Metabolic_Disorders_Guidelines.pdf',
  },
];

export const staticDrugsFilters = {
  letter: {
    items: [
      {id: 95, name: 'A'},
      {id: 96, name: 'B'},
      {id: 97, name: 'C'},
      {id: 98, name: 'D'},
      {id: 99, name: 'E'},
      {id: 100, name: 'F'},
      {id: 101, name: 'G'},
      {id: 102, name: 'H'},
      {id: 103, name: 'I'},
      {id: 104, name: 'J'},
      {id: 105, name: 'K'},
      {id: 106, name: 'L'},
      {id: 107, name: 'M'},
      {id: 108, name: 'N'},
      {id: 109, name: 'O'},
      {id: 110, name: 'P'},
      {id: 111, name: 'Q'},
      {id: 112, name: 'R'},
      {id: 113, name: 'S'},
      {id: 114, name: 'T'},
      {id: 115, name: 'U'},
      {id: 116, name: 'V'},
      {id: 117, name: 'W'},
      {id: 118, name: 'X'},
      {id: 119, name: 'Y'},
      {id: 120, name: 'Z'},
    ],
    key: 'letter_id',
    label: 'Letter',
    static: true,
  },
};

export const defaultImagesDrugsData = [
  require('../images/drugs/1.png'),
  require('../images/drugs/2.png'),
  require('../images/drugs/3.png'),
  require('../images/drugs/4.png'),
  require('../images/drugs/5.png'),
  require('../images/drugs/6.png'),
  require('../images/drugs/7.png'),
  require('../images/drugs/8.png'),
  require('../images/drugs/9.png'),
];

export const drugInfoData = [
  {
    key: 'Therapeutic_Indication',
    label: 'Therapeutic Indication',
    img: require('../images/drugs/1.png'),
  },
  {
    key: 'Mechanism_of_action',
    label: 'Mechanism of action',
    img: require('../images/drugs/2.png'),
  },
  {
    key: 'Pharmacokinetics_and_dynamics_of_the_drug',
    label: 'Pharmacokinetics and dynamics of the drug',
    img: require('../images/drugs/3.png'),
  },
  {
    key: 'Dosing_and_dosing_adjustments',
    label: 'Dosing and dosing adjustments',
    img: require('../images/drugs/4.png'),
  },
  {
    key: 'Storage_conditions',
    label: 'Storage conditions',
    img: require('../images/drugs/5.png'),
  },
  {
    key: 'Pediatric_dosing',
    label: 'Pediatric dosing',
    img: require('../images/drugs/6.png'),
  },
  {
    key: 'Major_adverse_effects',
    label: 'Major adverse effects',
    img: require('../images/drugs/7.png'),
  },
  {
    key: 'Contraindications',
    label: 'Contraindications',
    img: require('../images/drugs/8.png'),
  },
  {
    key: 'Drug-drug_interactions',
    label: 'Drug interactions',
    img: require('../images/drugs/9.png'),
  },
  {
    key: 'Pregnancy_considerations',
    label: 'Pregnancy considerations',
    img: require('../images/drugs/pregnancy_considerations.jpg'),
  },
  {
    key: 'Breastfeeding_and_lactation',
    label: 'Breastfeeding & Lactation',
    img: require('../images/drugs/breastfeeding_and_lactation.jpg'),
  },
  {
    key: 'Off-labels',
    label: 'Off-labels',
    img: require('../images/drugs/Off_label.jpg'),
  },
  {
    key: 'Pharmacoeconomics',
    label: 'Pharmacoeconomics',
    img: require('../images/drugs/pharmacoeconomics.png'),
  },
  {
    key: 'Pharmacogenetics',
    label: 'Pharmacogenetics',
    img: require('../images/drugs/Pharmacogenetics.jpg'),
  },
  {
    key: 'Studies',
    label: 'Latest Studies',
    img: require('../images/drugs/studies.jpg'),
  },
];

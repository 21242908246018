import {DrugItemDetailsType} from '../../../interfaces/api/tabs';
import {services} from '../../../api';

/**
 * this function to get Drug Details
 *
 * @return {DrugItemDetailsType[] | null} news details
 */
export const getDrugDetails = async (
  id: number,
  isDiseases?: boolean,
): Promise<DrugItemDetailsType[] | null> => {
  try {
    const res = await services.news.getDrugDetails(id, isDiseases);
    return res?.data;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('Get Drug Details FAIL: ', error);
    }
    return null;
  }
};

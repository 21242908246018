import http from '../../api/http';
import {api} from '../../api/api';

/**
 * Translates the values of an object to the specified language, excluding certain keys.
 *
 * @param languageCode
 * @param {object} tableObj - The object containing values to be translated.
 * @param {string[]} excludeKeys - An array of keys to be excluded from translation.
 * @param {string} originalLang - The original language code.
 * @returns {Promise<object>} - The translated object with original values for excluded keys.
 */
export const translateTableArticle = async (
  languageCode: string,
  tableObj: any,
  excludeKeys: string[] = [],
  originalLang: string = 'en',
): Promise<any> => {
  try {
    const body = {languageCode, tableObj, excludeKeys, originalLang};

    let res = tableObj;

    if (languageCode !== originalLang) {
      res = await http.post(
        'https://translation-six.vercel.app/' + api.translate.googleTranslate,
        body,
      );
    }

    if (res && typeof res === 'object') {
      const translatedObj = {...tableObj, ...res};
      excludeKeys.forEach(key => {
        if (tableObj[key] !== undefined) {
          translatedObj[key] = tableObj[key];
        }
      });
      return translatedObj;
    } else {
      throw new Error('Translation error');
    }
  } catch (e) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.warn(e);
    }
    return tableObj;
  }
};

import {getLocalData, setLocalData} from '../../../utils/storage';
import {keys} from '../../../api/keys';
import {showGlobalToast} from '../../../connected-components/toast/actions';
import {tr} from '../../../translation';

export const toggleFavorite = async (news: any) => {
  try {
    const list = await getLocalData(keys.FAVORITE);
    let favorites: any[] = [];
    if (list) {
      favorites = JSON.parse(list);
    }
    if (favorites.some(item => item.id === news.id)) {
      const idx = favorites.findIndex(item => item.id === news.id);
      favorites.splice(idx, 1);
      showGlobalToast({
        message: tr('favorite.removeFromFavorite'),
        type: 'success',
      });
    } else {
      favorites.push(news);
      showGlobalToast({message: tr('favorite.addToFavorite'), type: 'success'});
    }
    await setLocalData(keys.FAVORITE, JSON.stringify(favorites));
    return true;
  } catch (error) {
    showGlobalToast({message: tr('app.error'), type: 'error'});
    return Promise.reject();
  }
};

export const getFavorites = async () => {
  try {
    const list = await getLocalData(keys.FAVORITE);
    return list ? JSON.parse(list) : [];
  } catch (error) {
    return null;
  }
};

export const checkIfFavorite = async (news: any) => {
  try {
    const list = await getLocalData(keys.FAVORITE);
    if (list) {
      const jsonList = JSON.parse(list);
      return jsonList.some((item: any) => {
        return item?.id === news?.id;
      });
    }
    return false;
  } catch (error) {
    return false;
  }
};

const guidelinesDermatology = process.env.REACT_APP_PDF_URL + 'gl/dermatology/';

export const guidelinesDermatologyData = [
  {
    id: 0,
    title: 'Acne',
    pdf_link: guidelinesDermatology + 'Acne_Guidelines.pdf',
  },
  {
    id: 1,
    title: 'Anogenital Warts',
    pdf_link: guidelinesDermatology + 'Anogenital_Warts_Guidelines.pdf',
  },
  {
    id: 2,
    title: 'DDermatitis and Urticaria',
    pdf_link: guidelinesDermatology + 'Dermatitis_and_Urticaria_Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Lupus',
    pdf_link: guidelinesDermatology + 'Lupus_Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Psoriasis',
    pdf_link: guidelinesDermatology + 'Psoriasis_Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Rosacea',
    pdf_link: guidelinesDermatology + 'Rosacea_Guidelines.pdf',
  },
  {
    id: 6,
    title: 'Skin Cancer',
    pdf_link: guidelinesDermatology + 'Skin_Cancer_Guidelines.pdf',
  },
  {
    id: 7,
    title: 'Tinea Infection',
    pdf_link: guidelinesDermatology + 'TINEA_INFETION_GUIDELINES.pdf',
  },
  {
    id: 8,
    title: 'Varicella-Zoster Virus Infection',
    pdf_link:
      guidelinesDermatology + 'Varicella_Zoster_Virus_Infection_GUIDELINES.pdf',
  },
  {
    id: 9,
    title: 'Vitiligo',
    pdf_link: guidelinesDermatology + 'vitiligo_Guidelines.pdf',
  },
];

import React from 'react';
import styled, {useTheme} from 'styled-components';
import {Theme} from '../../theme/lightTheme';
import {DrugItemProps} from '../../interfaces/components/drugItem';
import {motion} from 'framer-motion';
import Image from '../Image';

const Container = styled(motion.button)<{
  isDynamicRatio?: boolean;
  borderRadius?: number;
}>`
  display: flex;
  flex: 1 1 calc(50% - 0.5rem);
  max-width: 25%;
  position: relative;
  flex-direction: column;
  background-color: ${({theme}) => theme.text.light};
  box-shadow: 0 4px 8px ${({theme}) => theme.shadow};
  border-radius: ${({borderRadius}) => borderRadius}px;
  ${({isDynamicRatio}) => !isDynamicRatio && 'aspect-ratio: 1'};
  border: 2px solid ${({theme}) => theme.text.light};
  overflow: hidden;
  @media (max-width: 768px) {
    max-width: 30%;
  }
`;
const ImageContainer = styled.div<{
  isDynamicRatio: boolean;
}>`
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
`;
const LinearGradientContainer = styled.div<{
  isDynamicRatio: boolean;
}>`
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    ${({theme, isDynamicRatio}) =>
        isDynamicRatio ? theme.primary + '88' : 'transparent'}
      0%,
    ${({theme, isDynamicRatio}) =>
        isDynamicRatio ? theme.secondary + '88' : theme.text.black + 'BB'}
      100%
  );
`;
const ImageItem = styled(Image)`
  width: 100%;
  height: 100%;
`;
const ContentContainer = styled.div`
  z-index: 2;
  margin-top: auto;
  width: 100%;
`;
const Label = styled.p<{isDynamicRatio?: boolean}>`
  width: 100%;
  font-size: ${({theme, isDynamicRatio}) =>
    isDynamicRatio ? theme.text.s8 : theme.text.s11};
  color: ${({theme}) => theme.text.light};
  padding: ${({isDynamicRatio}) => (isDynamicRatio ? '0.7rem' : '1rem')};
  text-align: center;
  z-index: 99;
  font-weight: bold;
  position: relative;
  @media (max-width: 768px) {
    padding: ${({isDynamicRatio}) => (isDynamicRatio ? '0.7rem' : '0.1rem')};
  }
`;

/**
 * Drug Item component.
 *
 * @param {DrugItemProps} props - The Drug Item component props.
 * @returns {JSX.Element} The rendered Drug Item component.
 */
const DrugItem: React.FC<DrugItemProps> = ({
  style,
  className,
  onPress,
  name,
  imageUri,
  defaultImage,
  isDynamicRatio,
  borderRadius = 10,
}: DrugItemProps): JSX.Element => {
  const theme = useTheme() as Theme;

  const _renderBackground = () => {
    return (
      <ImageContainer isDynamicRatio={isDynamicRatio}>
        <LinearGradientContainer isDynamicRatio={isDynamicRatio} />
        <ImageItem
          resizeMode={'cover'}
          placeholderImage={
            defaultImage
              ? defaultImage
              : require('../../assets/images/drugPlaceholder.png')
          }
          source={imageUri}
        />
      </ImageContainer>
    );
  };
  return (
    <Container
      borderRadius={borderRadius}
      disabled={!onPress}
      style={style}
      className={className}
      whileTap={
        !onPress
          ? {}
          : {
              opacity: 0.9,
              scale: 0.9,
              shadow: `0 8px 16px ${theme.shadow}`,
            }
      }
      isDynamicRatio={isDynamicRatio}
      onClick={onPress}>
      {!isDynamicRatio && _renderBackground()}
      <ContentContainer>
        {isDynamicRatio && _renderBackground()}
        <Label isDynamicRatio={isDynamicRatio}>{name || ''}</Label>
      </ContentContainer>
    </Container>
  );
};

export default DrugItem;

import {services} from '../../../api';
import {showGlobalToast} from '../../../connected-components/toast/actions';
import {QNAForm, QNAResponseData} from '../../../interfaces/api/qna';
import {tr} from '../../../translation';

/**
 * this function to get news List
 *
 * @return {any} result of news List
 */
export const submitQNA = async (
  body: QNAForm,
): Promise<QNAResponseData | null> => {
  try {
    const res = await services.qna.sendQNA(body);
    return res?.data;
  } catch (error: any) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.info('QNA FAIL: ', error);
    }
    showGlobalToast({
      message: tr('app.error'),
      type: 'error',
    });
    return null;
  }
};

const treatmentOptionsPediatrics =
  process.env.REACT_APP_PDF_URL + 'to/pediatrics/';

export const treatmentOptionsPediatricsData = [
  {
    id: 0,
    title: 'Asthma',
    pdf_link: treatmentOptionsPediatrics + 'Asthma_Treatment_Options.pdf',
  },
  {
    id: 1,
    title: 'Gastroenteritis and Constipation in Pediatrics',
    pdf_link:
      treatmentOptionsPediatrics +
      'Gastroenteritis_Constipation_Pediatrics_Treatment_Options.pdf',
  },
  {
    id: 2,
    title: 'Croup',
    pdf_link: treatmentOptionsPediatrics + 'Croup_Treatment_Options.pdf',
  },
  {
    id: 3,
    title: 'Influenza',
    pdf_link: treatmentOptionsPediatrics + 'Influenza_Treatment_Options.pdf',
  },
  {
    id: 4,
    title: 'Measles Mumps Rubella Diagnosis',
    pdf_link:
      treatmentOptionsPediatrics + 'MMR_Diagnosis_Treatment_Options.pdf',
  },
  {
    id: 5,
    title: 'Pediatric Allergic Rhinitis',
    pdf_link:
      treatmentOptionsPediatrics +
      'Pediatric_Allergic_Rhinitis_Treatment_Options.pdf',
  },
  {
    id: 6,
    title: 'Pediatric Hepatitis Guidelines',
    pdf_link:
      treatmentOptionsPediatrics + 'Pediatric_Hepatitis_Treatment_Options.pdf',
  },
  {
    id: 7,
    title: 'Pediatric Pneumonia',
    pdf_link:
      treatmentOptionsPediatrics + 'Pediatric_Pneumonia_Treatment_Options.pdf',
  },
  {
    id: 8,
    title: 'Pediatric Urinary Tract Infection',
    pdf_link:
      treatmentOptionsPediatrics + 'Pediatric_UTI_Treatment_Options.pdf',
  },
];

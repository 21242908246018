import React from 'react';
import styled from 'styled-components';
import {CustomGridProps} from '../../interfaces/components/customGrid';
import {motion} from 'framer-motion';

const Container = styled(motion.div)`
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const CustomGrid: React.FC<CustomGridProps> = ({
  data,
  style,
  className,
  rowStyle,
  renderItem,
  isMobile,
}: CustomGridProps) => {
  let localIndex = 0;
  const renderRow = (items: any[]) => (
    <Row style={rowStyle}>
      {items.map(item => {
        localIndex += 1;
        return renderItem(item, localIndex);
      })}
    </Row>
  );

  const renderGrid = () => {
    const grid = [];
    let index = 0;

    // First row with 3 items
    grid.push(renderRow(data.slice(index, index + (isMobile ? 2 : 3))));
    index += isMobile ? 2 : 3;

    // Middle rows with 4 items each
    while (index < data.length - (isMobile ? 3 : 4)) {
      grid.push(renderRow(data.slice(index, index + (isMobile ? 3 : 4))));
      index += isMobile ? 3 : 4;
    }

    // Last row with 3 items
    if (index < data.length) {
      grid.push(renderRow(data.slice(index, data.length)));
    }

    return grid;
  };

  return (
    <Container layout className={className} style={style}>
      {renderGrid()}
    </Container>
  );
};

export default CustomGrid;

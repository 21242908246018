import {useEffect} from 'react';
import {store} from '../../redux/store';
import {deepLinkingTypes} from '../enums';
import {useNavigate} from 'react-router-dom';
import {login} from '../../screens/auth/login/reducer';
import {getLocalData} from '../storage';
import {keys} from '../../api/keys';
import {LoginDataType} from '../../interfaces/api/auth/login';
import {getShareLinkNewsDetails} from '../../screens/content/article/actions';
import Paths from '../../config/paths';

/**
 * Custom hook to handle deep linking.
 * This hook handles the URL processing internally.
 */
const useDeepLinking = (): void => {
  const navigate = useNavigate();
  useEffect(() => {
    handleDeepLink();
  }, []);

  // Function to handle the deep link
  const handleDeepLink = async () => {
    const token = new URL(window.location.href || '').searchParams.get('token');
    if (
      !token ||
      !(window.location.href || '').includes(deepLinkingTypes.article)
    ) {
      return;
    }
    const newsItem = await getShareLinkNewsDetails(token);
    if (!newsItem) {
      navigate(Paths.auth.login, {
        replace: true,
      });
      return;
    }

    const currentUser = (await getLocalData(
      keys.CURRENT_USER,
    )) as LoginDataType;
    if (!currentUser?.token) {
      store.dispatch(
        login({
          token: process.env.REACT_APP_OPEN_ACCESS_ARTICLE_TOKEN,
        }),
      );
    }
    navigate(Paths.content.article, {
      replace: true,
      state: {
        ...(newsItem || {}),
        id: newsItem?.id,
      },
    });

    //open deeplink on mobile app
    const params = new URLSearchParams();
    Object.keys(newsItem).forEach(key => {
      if (newsItem[key]) {
        params.append(key, String(newsItem[key]));
      }
    });

    openDeepLink(
      `${process.env.REACT_APP_DEEP_LINK_SCHEME}article?${params.toString()}`,
    );
  };
};

/**
 * Opens a deep link in a mobile app.
 * If the deep link fails, it can optionally redirect to a fallback URL.
 *
 * @param {string} deepLink - The deep link URL to open the mobile app.
 */
const openDeepLink = (deepLink: string) => {
  try {
    console.info(deepLink);
    const anchor = document.createElement('a');
    anchor.href = deepLink;
    anchor.rel = 'noopener noreferrer';
    document.body.appendChild(anchor);

    // Attempt to open the deep link
    anchor.click();

    // Remove the anchor after use
    document.body.removeChild(anchor);
  } catch (error) {
    if (process.env.REACT_APP_DEBUG === 'true') {
      console.error('Failed to open deep link:', error);
    }
  }
};

export default useDeepLinking;

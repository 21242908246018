const diagnosticToolsOphthalmology =
  process.env.REACT_APP_PDF_URL + 'dt/ophthalmology/';

export const diagnosticToolsOphthalmologyData = [
  {
    id: 0,
    title: 'Glaucoma',
    // pdf_link: diagnosticToolsOphthalmology + 'Glaucoma_Diagnostic_Tool.pdf',
    pdf_link: null,
  },
  {
    id: 1,
    title: 'Eye Infection',
    // pdf_link:
    //   diagnosticToolsOphthalmology + 'Eye_Infection_Diagnostic_Tool.pdf',
  },
  {
    id: 2,
    title: 'Dry Eye Syndrome',
    pdf_link: null,
    // pdf_link:
    //   diagnosticToolsOphthalmology + 'Dry_Eye_Syndrome_Diagnostic_Tool.pdf',
  },
  {
    id: 3,
    title: 'Diabetic Retinopathy',
    pdf_link: null,
    // pdf_link:
    //   diagnosticToolsOphthalmology + 'Diabetic_Retinopathy_Diagnostic_Tool.pdf',
  },
  {
    id: 4,
    title: 'Refractive Errors',
    pdf_link: null,
    // pdf_link:
    //   diagnosticToolsOphthalmology + 'Refractive_Errors_Diagnostic_Tool.pdf',
  },
  {
    id: 5,
    title: 'Age-related Macular Degeneration',
    pdf_link: null,
    // pdf_link:
    //   diagnosticToolsOphthalmology +
    //   'Age_Related_Macular_Degeneration_Diagnostic_Tool.pdf',
  },
  {
    id: 6,
    title: 'Cataract',
    pdf_link: null,
    // pdf_link: diagnosticToolsOphthalmology + 'Cataract_Diagnostic_Tool.pdf',
  },
];

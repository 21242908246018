import React, {useRef, useState} from 'react';
import styled, {useTheme} from 'styled-components';
import HeaderTabs from '../../components/headerTabs';
import Drawer from '../../components/drawer';
import {RootState} from '../../redux/store';
import {useSelector} from 'react-redux';
import {motion} from 'framer-motion';
import Header from '../../components/header';
import {Theme} from '../../theme/lightTheme';
import 'react-lazy-load-image-component/src/effects/blur.css';
import defaultBackgroundImage from '../../assets/images/loginBackground.jpg';
import defaultLoginBackgroundMobile from '../../assets/images/loginBackgroundMobile.jpg';
import {useMainLayout} from '../../route/routeMainLayout/context';
import {Outlet} from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${({theme}) => (theme.isMobile ? 'column' : 'row')};
  width: ${({theme}) => theme.dimensions.wdp(100)}px;
  @media (max-width: 768px) {
    overflow-y: scroll;
    height: ${({theme}) => theme.dimensions.hdp(100)}px;
  }
  @media (min-width: 768px) {
    overflow-y: hidden;
    min-height: ${({theme}) => theme.dimensions.hdp(100)}px;
  }
`;
const PageContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;
const TopOverlay = styled.div`
  position: fixed;
  top: 0;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  height: 60%;
  background: linear-gradient(
    to bottom,
    ${({theme}) => theme.text.light + 'EE'} 1%,
    rgba(255, 255, 255, 0)
  );
`;
const MainOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  height: ${({theme}) => theme.dimensions.hdp(100)}px;
  width: ${({theme}) => theme.dimensions.wdp(100)}px;
`;
const Background = styled.div<any>`
  background-image: ${({
      backgroundImage,
      showBackgroundImage,
      theme,
      backgroundMobileImage,
    }) =>
      !showBackgroundImage
        ? ''
        : theme.isMobile
          ? `url(${backgroundMobileImage})`
          : `url(${backgroundImage})`},
    linear-gradient(
      to bottom,
      ${({theme}) => theme.primary},
      ${({theme}) => theme.secondary}
    );
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  ${({theme}) => theme.isMobile && 'background-position: right;'};
`;

const BottomContainer = styled.div`
  width: 100%;
  z-index: 1001;
  position: sticky;
  bottom: 0;
  padding: 7rem 0 2rem 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;
const MainContent = styled(motion.div)`
  display: flex;
  flex: 1;
  z-index: 1;
  width: 100%;
`;

const variants = {
  hidden: {y: '10vh'},
  enter: {y: 0},
  exit: {y: '-10vh'},
};

const MainLayout: React.FC<any> = ({children}): JSX.Element => {
  const {
    props: {
      showBackgroundImage = true,
      backgroundImage,
      backgroundMobileImage,
      showHeader,
      overlayColor,
      hideTopOverlay,
      hideAnimation,
      showDrawer = true,
      showHeaderTabs = true,
      className,
      backgroundStyle,
      ...props
    },
  } = useMainLayout();
  const theme = useTheme() as Theme;
  const overlayBackgroundColor = overlayColor || theme.defaultOverlayScreen;
  const drawerRef = useRef<{toggle: () => void} | null>(null);
  const {currentUser} = useSelector((state: RootState) => state.auth);
  const isAuthenticated = currentUser?.token;

  const [isSidebarOpen, setSidebarState] = useState(false);
  const backgroundVariants = {
    hidden: {backgroundColor: overlayBackgroundColor},
    enter: {
      backgroundColor: overlayColor,
      filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2))',
      backdropFilter: 'blur(3px)',
    },
  };

  return (
    <Container className={className} key={currentUser?.token}>
      {isAuthenticated && showDrawer && (
        <Drawer
          isSidebarOpen={isSidebarOpen}
          setSidebarState={setSidebarState}
          ref={drawerRef}
        />
      )}

      <PageContainer>
        {!hideTopOverlay && <TopOverlay />}
        <MainOverlay
          animate={
            overlayBackgroundColor !== theme.defaultOverlayScreen
              ? 'enter'
              : 'hidden'
          }
          variants={backgroundVariants}
          transition={{
            duration: 0.6,
            ease: [0.25, 0.1, 0.25, 1],
            backgroundColor: {duration: 0.6},
            filter: {duration: 0.6},
            backdropFilter: {duration: 0.6},
          }}
        />
        <Background
          className={'background'}
          style={backgroundStyle}
          showBackgroundImage={showBackgroundImage}
          backgroundMobileImage={
            backgroundMobileImage ||
            defaultLoginBackgroundMobile ||
            backgroundImage ||
            defaultBackgroundImage ||
            defaultBackgroundImage
          }
          backgroundImage={backgroundImage || defaultBackgroundImage}
        />
        {showHeader && (
          <HeaderTabs
            {...props}
            showHeaderTabs={showHeaderTabs}
            onMenuPress={drawerRef?.current?.toggle}
            isSidebarOpen={isSidebarOpen}
          />
        )}
        <MainContent
          variants={variants}
          initial={hideAnimation ? false : 'hidden'}
          animate={hideAnimation ? 'enter' : 'enter'}
          exit="exit"
          transition={{
            type: 'spring',
          }}
          style={{
            marginTop: !!showHeader ? (theme.isMobile ? '4%' : '1.5rem') : 0,
          }}>
          {!!children ? children : <Outlet />}
        </MainContent>
      </PageContainer>

      {theme.isMobile && showHeader && showHeaderTabs && (
        <BottomContainer>
          <Header />
        </BottomContainer>
      )}
    </Container>
  );
};

export default MainLayout;

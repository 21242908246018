import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled, {css, keyframes, useTheme} from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {Theme} from '../../../theme/lightTheme';
import {
  FilterData,
  GetTabNewsData,
  NewsItemType,
} from '../../../interfaces/api/tabs';
import {RootState} from '../../../redux/store';
import {useSelector} from 'react-redux';
import Paths from '../../../config/paths';
import NewsCard from '../../../components/newsCard';
import {SpecialityType, TabIds} from '../../../utils/enums';
import {RecommendationsData} from '../../../interfaces/api/content/recomendations';
import {getRecommendations, getRecommendationsByTab} from '../home/actions';
import {getPublishersByLetter, getTabNews} from '../tabNews/actions';
import {searchNews} from '../search/actions';
import {debounce} from 'lodash';
import Icon from '../../../components/icon';
import ErrorView from '../../../components/errorView';
import {tr} from '../../../translation';
import NewsSkeleton from '../../../components/newsSkeleton';
import {useSetMainLayoutProps} from '../../../route/routeMainLayout/context';
import AnimatedRouterLayout from '../../../components/animatedRouterLayout';
import {getFilterItemByKey, removeFilters} from '../../../utils/helper';
import DrugFilterItem from '../../../components/drugFilterItem';
import {
  FilterItemContainer,
  FilterLabel,
  FiltersContainer,
  FilterValuesContainer,
} from '../drugs';
import {handleChange} from '../../../utils/form';
import {customFiltersKeys} from '../../../assets/static/tabsData';
import {showFiltersModal} from '../../../connected-components/filtersModal/actions';
import SearchDrugs from '../../../components/searchDrugs';
import {motion} from 'framer-motion';
import FiltersModal from '../../../connected-components/filtersModal';
import MyPressable from '../../../components/myPressable';
import {isSmallHeight} from '../../../utils/responsive';
import {fadeIn, fadeOut} from '../search';
import Chip from '../../../components/chip';

export const zoomIn = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

export const zoomOut = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
`;

const fadeInOutDownUp = {
  hidden: {
    opacity: 0,
    y: 40,
    height: '0',
    overflow: 'hidden',
    marginBottom: '0rem',
    zIndex: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {duration: 0.3},
    height: 'auto',
    overflow: 'visible',
    marginBottom: '1rem',
    zIndex: 22,
  },
  exit: {
    opacity: 0,
    y: -40,
    transition: {duration: 0.3},
    height: 0,
    overflow: 'hidden',
    marginBottom: '0rem',
    zIndex: 0,
  },
};

const Container = styled(AnimatedRouterLayout)<{flex: boolean}>`
  ${({flex}) =>
    flex &&
    `
     display: flex;
     margin-bottom: auto;
    `};

  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 3% 15% 5% 15%;
  overflow-x: hidden;
  @media (max-width: 550px) {
    padding: 5%;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
  }
`;
const HeaderContainer = styled(motion.div)`
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  width: 100%;
`;
const HeaderContentContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  position: relative;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(
    360deg,
    ${({theme}) => theme.secondary + '77'} -68.52%,
    rgba(0, 159, 156, 0) 122.02%
  );
  backdrop-filter: blur(16px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
  border-radius: 8px;
  z-index: 999;
  margin-bottom: 1rem;
  padding: 1rem;
`;
const HeaderIconContainer = styled(motion.div)`
  display: flex;
  flex: 1;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
`;
const PublisherContainer = styled(motion.div)`
  display: flex;
  position: relative;
`;
const IconContainer = styled(MyPressable)`
  width: ${({theme}) =>
    `calc(${theme.text.s55} * ${theme.isMobile ? '3.6' : '2.8'})`};
  height: ${({theme}) =>
    `calc(${theme.text.s55} * ${theme.isMobile ? '3.6' : '2.8'})`};
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.secondary + '99'};
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.textInput.border};
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  box-shadow: 0 2px 4px ${({theme}) => theme.shadow};
  backdrop-filter: blur(6px);
`;
const FilterBadgeContainer = styled(motion.div)`
  position: absolute;
  display: flex;
  right: ${({theme}) => `calc(-${theme.text.s6} / 2)`};
  bottom: ${({theme}) => `calc(-${theme.text.s6} / 2)`};
  height: ${({theme}) => theme.text.s6};
  border-radius: 100px;
  aspect-ratio: 1;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme.text.light};
`;
const FilterBadge = styled.p`
  font-size: ${({theme}) => theme.text.s10};
  padding: ${({theme}) => theme.text.s10};
  color: ${({theme}) => theme.secondary};
  text-align: center;
`;
const FilterIconContainer = styled.div`
  position: relative;
`;
export const BlurContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    360deg,
    ${({theme}) => theme.secondary + '77'} -68.52%,
    rgba(0, 159, 156, 0.15) 122.02%
  );
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(16px);
  border-radius: 26px;
  padding: 1rem;
`;
export const EmptyText = styled.p`
  font-size: ${({theme}) => theme.text.s7};
  font-weight: 400;
  color: ${({theme}) => theme.text.light};
  width: 80%;
  padding-top: 1rem;
  text-align: center;
`;
const NewsListItem = styled.div`
  margin-bottom: 1rem;
  width: 48%;
  @media (max-width: 550px) {
    width: 100%;
  }
`;
export const AnimatedDiv = styled.div<{isZoomedIn?: boolean}>`
  animation: ${zoomOut} 0.3s ease-in-out forwards;
  transform-origin: center;
  display: none;
  ${({isZoomedIn}) =>
    isZoomedIn &&
    css`
      animation: ${zoomIn} 0.3s ease-in-out forwards;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      align-self: center;
    `};
`;
const SubTapItemContainer = styled.div`
  margin-bottom: 3%;
  margin-right: 1rem;
  @media (max-width: 550px) {
    margin: 3%;
  }
`;
const FadeContainer = styled(AnimatedDiv)`
  animation: ${zoomOut} 0.3s ease-in-out forwards;
  transform-origin: center;
  display: none;
  ${({isZoomedIn}) =>
    isZoomedIn &&
    css`
      animation: ${zoomIn} 0.3s ease-in-out forwards;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      align-self: center;
    `};
`;
const SubTapContainer = styled.div`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: 550px) {
    padding: 0;
    width: 100vw;
  }
  @media (max-width: 550px) {
    flex-wrap: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  z-index: 1;
`;

const pageSize = 20;

const News = () => {
  const navigation = useNavigate();
  const {
    filtersResult: prevFiltersResult,
    filters: prevFilters,
    tabId,
    search: prevSearch,
    isRecommendation,
    isRecommendationByTab,
    isHomeRecommendation,
    staticRecommendation,
    recommendationsFilters,
    newsFilters,
  } = useLocation().state || {};

  const filters =
    (isRecommendationByTab ? recommendationsFilters : newsFilters) ||
    prevFilters;

  const [filtersResult, setFiltersResult] = useState<any>(
    prevFiltersResult || {},
  );

  const onSearchPress = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    if (Object.keys(filtersResult || {}).length === 0) {
      queryParams.delete('filters');
    } else {
      queryParams.set('filters', JSON.stringify(filtersResult));
    }
    navigation(
      {pathname: Paths.content.search, search: queryParams.toString()},
      {
        state: {tabId, filters, filtersResult},
      },
    );
  }, [filters, filtersResult, location, tabId, navigation]);

  useSetMainLayoutProps({
    showHeader: true,
    showBack: true,
    showSearch: false,
  });
  const theme = useTheme() as Theme;
  const {currentUser} = useSelector((state: RootState) => state.auth);
  const [data, setData] = useState<NewsItemType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  // search
  const [localSearchResults, setLocalSearchResults] =
    useState<NewsItemType[]>(data);
  const [search, setSearch] = useState(prevSearch || '');
  const [newsCount, setNewsCount] = useState('0');
  // Publishers filter
  const [isLoadingPublishers, setIsLoadingPublishers] = useState(false);
  const [isErrorPublishers, setIsErrorPublishers] = useState(false);
  const [publishersPage, setPublishersPage] = useState(1);
  const [isLastPagePublishers, setIsLastPagePublishers] =
    useState<boolean>(false);
  const [publishersData, setPublishersData] = useState<NewsItemType[]>([]);
  const [isPublisherVisible, setIsPublisherVisible] = useState(false);
  const [isFilterModalShouldClose, setIsFilterModalShouldClose] =
    useState<any>(false);
  const [selectedPublisher, setSelectedPublisher] = useState<any>({});
  const [selectedPublisherFilter, setSelectedPublisherFilter] = useState<any>(
    {},
  );
  const [selectedPublisherAlphabet, setSelectedPublisherAlphabet] =
    useState('');

  const displayedData =
    staticRecommendation && (search || '').trim().length > 0
      ? localSearchResults
      : data;

  const filtersNum = useMemo(
    () =>
      Object.keys(filtersResult || {}).filter(
        item => !!getFilterItemByKey(filters, item, filtersResult[item]),
      ).length + (filtersResult?.publisher ? 1 : 0),
    [filtersResult],
  );

  const loadMore = async (refresh = false) => {
    if (staticRecommendation) {
      const specialtyId = currentUser?.speciality_id;
      const specialtyMapping = {
        [SpecialityType.Cardiology]:
          staticRecommendation[SpecialityType.Cardiology],
        [SpecialityType.Respiratory]:
          staticRecommendation[SpecialityType.Respiratory],
        [SpecialityType.Endocrinology]:
          staticRecommendation[SpecialityType.Endocrinology],
        [SpecialityType.Dermatology]:
          staticRecommendation[SpecialityType.Dermatology],
        [SpecialityType.Neurology]:
          staticRecommendation[SpecialityType.Neurology],
        [SpecialityType.Ophthalmology]:
          staticRecommendation[SpecialityType.Ophthalmology],
        [SpecialityType.Pediatrics]:
          staticRecommendation[SpecialityType.Pediatrics],
        [SpecialityType.Urology]: staticRecommendation[SpecialityType.Urology],
      };
      const specialtyData = specialtyMapping[+(specialtyId || '')] || [];
      setData(specialtyData);
      return;
    }
    if (isLoading && !refresh) {
      return;
    }

    setIsError(false);
    if (!isLastPage || refresh) {
      if (refresh) {
        setData([]);
      }
      setIsLoading(true);
      let result: GetTabNewsData | RecommendationsData | null = null;
      if (isRecommendationByTab) {
        result = await getRecommendationsByTab({
          tabId,
          page: refresh ? 1 : page,
          pageSize,
          ...filtersResult,
        });
      } else if (isHomeRecommendation) {
        if ((search || '').trim().length > 0) {
          result = await searchNews({
            page: refresh ? 1 : page,
            pageSize,
            keywords: search,
          });
        } else {
          result = await getRecommendations(refresh ? 1 : page, pageSize);
        }
      } else if ((search || '').trim().length > 0 && tabId) {
        result = await searchNews({
          keywords: search,
          tabId,
          page: refresh ? 1 : page,
          pageSize,
          ...filtersResult,
        });
      } else if (tabId) {
        result = await getTabNews({
          tabId,
          page: refresh ? 1 : page,
          pageSize,
          ...filtersResult,
        });
      }

      if (!result) {
        setIsError(true);
        setIsLastPage(true);
        setIsLoading(false);
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      setNewsCount(result?.totalCount || '0');
      setData(prev => {
        if (refresh) {
          return [...(result?.news || [])];
        } else {
          return [...prev, ...(result?.news || [])].filter(
            (value, index, self) => {
              return (
                self.findIndex(obj =>
                  ['id'].every(key => obj[key] === value[key]),
                ) === index
              );
            },
          );
        }
      });

      setPage(prev => (refresh ? 2 : prev + 1));
      const totalPageCount = Math.ceil(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        parseFloat(result?.totalCount || result?.total || '2') / pageSize,
      );
      setIsLastPage((refresh ? 1 : page) >= totalPageCount);
      setIsLoading(false);
    }
  };

  const loadMorePublishers = async (refresh = false) => {
    if (isLoadingPublishers) {
      return;
    }
    setIsErrorPublishers(false);
    if (!isLastPagePublishers || refresh) {
      if (refresh) {
        setPublishersData([]);
      }
      setIsLoadingPublishers(true);
      let result: GetTabNewsData | null = null;
      result = await getPublishersByLetter({
        tabId,
        page: refresh ? 1 : publishersPage,
        pageSize: 20,
        ...filtersResult,
        keywords: selectedPublisherFilter?.publisher || '',
        ...selectedPublisherFilter,
      });

      if (!result) {
        setIsErrorPublishers(true);
        setIsLoadingPublishers(false);
        return;
      }

      setPublishersData(prev => {
        if (refresh) {
          return [...result?.news];
        } else {
          return [...prev, ...result?.news].filter((value, index, self) => {
            return (
              self.findIndex(obj =>
                ['id'].every(key => obj[key] === value[key]),
              ) === index
            );
          });
        }
      });

      setPublishersPage(prev => (refresh ? 2 : prev + 1));
      const totalPageCount = Math.ceil(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        parseFloat(result?.totalCount || result?.total || '2') / 20,
      );
      setIsLastPagePublishers((refresh ? 1 : page) >= totalPageCount);
      setIsLoadingPublishers(false);
    }
  };

  useEffect(() => {
    loadMore(true);
  }, [filtersResult, search]);

  useEffect(() => {
    if (filters?.publisher) {
      loadMorePublishers(true);
    }
  }, [selectedPublisherFilter, tabId, currentUser]);

  useEffect(() => {
    const handleScroll = debounce(() => {
      const scrollPosition =
        window.innerHeight + document.documentElement.scrollTop;
      const threshold =
        document.documentElement.offsetHeight - window.innerHeight / 2;

      if (scrollPosition >= threshold && !isLoading && !isLastPage) {
        loadMore();
      }
    }, 200);

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel(); // Cancel any pending debounce calls
    };
  }, [isLoading, isError, isLastPage, page]);

  const _renderItem = (item: NewsItemType) => {
    return (
      <NewsListItem key={item?.id}>
        <NewsCard
          title={item?.title || ''}
          description={item?.description || ''}
          image={item?.img}
          style={{
            opacity: !!staticRecommendation && !item?.pdf_link ? 0.7 : 1,
            pointerEvents:
              !!staticRecommendation && !item?.pdf_link ? 'none' : 'auto',
          }}
          onPress={() => {
            if (staticRecommendation) {
              if (item?.pdf_link) {
                navigation(Paths.content.pdfViewer, {
                  state: {...item},
                });
              }
              return;
            }
            if (+tabId === TabIds.Drugs) {
              navigation(Paths.content.drugs, {
                state: {id: item?.id, title: item?.title || item?.name || ''},
              });
              return;
            }
            navigation(Paths.content.article, {
              state: {
                item,
                isRecommendation: isRecommendation,
                isRecommendationByTab: isRecommendationByTab,
              },
            });
          }}
        />
      </NewsListItem>
    );
  };

  const openFilterModal = () => {
    const tempFilters = removeFilters(filters, customFiltersKeys);
    if (tempFilters === null) {
      return;
    }

    showFiltersModal({
      filters: tempFilters,
      filtersValues: filtersResult,
      onSubmit: val => {
        setFiltersResult(prev => {
          return {...(prev || {}), ...(val || {})};
        });
      },
    });
  };

  const searchStaticRecommendations = (text: string) => {
    const term = (text || '')?.toLowerCase().trim();

    if (term.length === 0) {
      setLocalSearchResults(data);
      setNewsCount(data.length + '');
      return;
    }

    const results = data?.filter(item => {
      const wordsInTitle = (item?.title || '')?.toLowerCase().split(' ');
      return wordsInTitle.some(word => (word || '').includes(term));
    });
    setNewsCount(results?.length + '');
    setLocalSearchResults(results);
  };

  const _renderFilterItem = (item: FilterData) => {
    return (
      <FilterItemContainer key={item?.key}>
        <FilterLabel>
          {'Find Publisher by Alphabet' || item?.label || ''}
        </FilterLabel>
        <FilterValuesContainer>
          {(item?.items || []).map(filterItem => (
            <DrugFilterItem
              key={filterItem?.id + filterItem?.name}
              itemKey={item?.key}
              name={filterItem?.name}
              id={filterItem?.id}
              isLastPage={isLastPagePublishers}
              isSkeleton={item?.static}
              data={publishersData || []}
              onPress={() => {
                setIsFilterModalShouldClose(false);
                setSelectedPublisherFilter({[item.key]: filterItem.id});
              }}
              onConfirmSelection={(selectedItem: any) => {
                setSelectedPublisher(selectedItem);
                if (Object.values(selectedItem || {}).length > 0) {
                  handleChange(
                    item.key,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    Object.values(selectedItem || {})?.[0]?.id,
                    setFiltersResult,
                  );
                }
                if (Object.keys(selectedItem || {}).length > 0) {
                  setSelectedPublisherAlphabet(
                    Object.keys(selectedItem || {})[0],
                  );
                }
              }}
              selectedDrug={selectedPublisher}
              isLoading={isLoadingPublishers}
              isError={isErrorPublishers}
              loadMore={() => loadMorePublishers()}
              isFilterModalShouldClose={isFilterModalShouldClose}
            />
          ))}
        </FilterValuesContainer>
      </FilterItemContainer>
    );
  };

  const _renderFilters = (item: string) => {
    const res = getFilterItemByKey(
      filters || {},
      item,
      filtersResult?.[item] || {},
    );
    if (!res) {
      return null;
    }
    return (
      <SubTapItemContainer key={item}>
        <Chip
          label={
            res?.filterData?.label +
            ': ' +
            (item === 'publisher'
              ? selectedPublisher?.[selectedPublisherAlphabet]?.name || ''
              : res?.filterItem?.name)
          }
          onPress={() => {
            if (item === 'publisher') {
              setIsPublisherVisible(false);
              setSelectedPublisherAlphabet('');
              setSelectedPublisher({});
            }

            const temp = {...(filtersResult || {})};
            delete temp[item];
            setFiltersResult({...temp});
          }}
          rightIconName={'close'}
          rightIconType={'SVG'}
          rightIconColor={theme.text.light}
        />
      </SubTapItemContainer>
    );
  };

  return (
    <Container
      flex={!(!isLoading && !isError && (displayedData || []).length === 0)}>
      {(!(isRecommendation || isRecommendationByTab) ||
        !(
          isRecommendation ||
          isHomeRecommendation ||
          staticRecommendation ||
          Object.keys(removeFilters(filters, customFiltersKeys) || {})
            .length === 0
        ) ||
        !!filters?.publisher) && (
        <HeaderContainer>
          <HeaderContentContainer>
            <HeaderIconContainer>
              {!(isRecommendation || isRecommendationByTab) && (
                <SearchDrugs
                  search={search}
                  setSearch={text => {
                    if (staticRecommendation) {
                      searchStaticRecommendations(text);
                    }
                    setSearch(text);
                    setIsFilterModalShouldClose(true);
                  }}
                  isLoading={isLoading}
                  isError={isError}
                  hideMenu
                  resultsNum={
                    staticRecommendation
                      ? search.length > 0
                        ? newsCount || '0'
                        : undefined
                      : newsCount || '0'
                  }
                  style={{marginBottom: 0, flex: 1}}
                />
              )}
              {!!filters?.publisher && (
                <FilterIconContainer>
                  <IconContainer
                    disabled={isLoading || isError}
                    onPress={() => setIsPublisherVisible(prev => !prev)}
                    style={{marginLeft: '1rem'}}
                    containerStyle={{overflow: 'visible'}}>
                    <Icon
                      type={'SVG'}
                      name={'publisher'}
                      size={theme.text.s4}
                      color={theme.text.light}
                    />
                    {!!selectedPublisherAlphabet && (
                      <FilterBadgeContainer
                        initial={{scale: 0.8, opacity: 0}}
                        animate={{scale: 1, opacity: 1}}
                        exit={{scale: 0.8, opacity: 0}}>
                        <FilterBadge>{selectedPublisherAlphabet}</FilterBadge>
                      </FilterBadgeContainer>
                    )}
                  </IconContainer>
                  <FiltersModal />
                </FilterIconContainer>
              )}
              {!(
                isRecommendation ||
                isHomeRecommendation ||
                staticRecommendation ||
                Object.keys(removeFilters(filters, customFiltersKeys) || {})
                  .length === 0
              ) && (
                <FilterIconContainer>
                  <IconContainer
                    disabled={isLoading || isError}
                    onPress={openFilterModal}
                    style={{marginLeft: '1rem'}}
                    containerStyle={{overflow: 'visible'}}>
                    <Icon
                      type={'SVG'}
                      name={'filtersSolid'}
                      size={theme.text.s4}
                      color={theme.text.light}
                    />
                    {(filtersNum || 0) - (!!filtersResult.publisher ? 2 : 0) >
                      0 && (
                      <FilterBadgeContainer
                        initial={{scale: 0.8, opacity: 0}}
                        animate={{scale: 1, opacity: 1}}
                        exit={{scale: 0.8, opacity: 0}}>
                        <FilterBadge>
                          {filtersNum - (!!filtersResult.publisher ? 2 : 0)}
                        </FilterBadge>
                      </FilterBadgeContainer>
                    )}
                  </IconContainer>
                  <FiltersModal />
                </FilterIconContainer>
              )}
            </HeaderIconContainer>
            <PublisherContainer
              initial="hidden"
              animate={isPublisherVisible ? 'visible' : 'exit'}
              exit="exit"
              style={{
                marginBottom: 0,
                marginTop: isPublisherVisible ? '1.5rem' : 0,
              }}
              variants={fadeInOutDownUp}>
              <FiltersContainer>
                {_renderFilterItem(filters?.publisher || {})}
              </FiltersContainer>
            </PublisherContainer>
            <PublisherContainer
              initial="hidden"
              animate={
                Object.keys(filtersResult || {}).length > 0 ? 'visible' : 'exit'
              }
              style={{
                marginTop:
                  Object.keys(filtersResult || {}).length > 0 &&
                  !isPublisherVisible
                    ? '1.5rem'
                    : 0,
              }}
              exit="exit"
              variants={fadeInOutDownUp}>
              <SubTapContainer>
                {(Object.keys(filtersResult || {}) || []).map(_renderFilters)}
              </SubTapContainer>
            </PublisherContainer>
          </HeaderContentContainer>
        </HeaderContainer>
      )}
      {!isError &&
        displayedData?.length > 0 &&
        (displayedData || []).map(_renderItem)}
      {isLoading && !isError && (displayedData || []).length === 0 && (
        <NewsSkeleton />
      )}
      <AnimatedDiv
        isZoomedIn={
          !isLoading && !isError && (displayedData || []).length === 0
        }>
        <BlurContainer style={{minWidth: '40%', aspectRatio: 1}}>
          <Icon
            name={'empty'}
            type={'SVG'}
            color={theme.text.light}
            size={theme.text.s2}
          />
          <EmptyText>{tr('app.empty')}</EmptyText>
        </BlurContainer>
      </AnimatedDiv>
      <AnimatedDiv isZoomedIn={isError}>
        <BlurContainer style={{minWidth: '40%', aspectRatio: 1}}>
          <ErrorView onRefresh={() => loadMore(true)} />
        </BlurContainer>
      </AnimatedDiv>
      <AnimatedDiv
        style={{display: displayedData?.length > 0 ? 'flex' : 'none'}}
        isZoomedIn={
          isLoading && !isError && !isLastPage && displayedData?.length > 0
        }>
        <BlurContainer>
          <Icon
            name={'loader'}
            type={'SVG'}
            color={theme.text.light}
            size={theme.text.s3}
          />
        </BlurContainer>
      </AnimatedDiv>
    </Container>
  );
};
export default News;

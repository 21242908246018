import {SpecialityType, TabIds} from '../../utils/enums';
import {treatmentOptionsRespiratoryData} from './pdfs/to/respiratory';
import {treatmentOptionsCardiologyData} from './pdfs/to/cardiology';
import {treatmentOptionsEndocrinologyData} from './pdfs/to/endocrinology';
import {treatmentOptionsDermatologyData} from './pdfs/to/dermatology';
import {guidelinesRespiratoryData} from './pdfs/gl/respiratory';
import {guidelinesCardiologyData} from './pdfs/gl/cardiology';
import {guidelinesEndocrinologyData} from './pdfs/gl/endocrinology';
import {guidelinesDermatologyData} from './pdfs/gl/dermatology';
import {diagnosticToolsRespiratoryData} from './pdfs/dt/respiratory';
import {diagnosticToolsCardiologyData} from './pdfs/dt/cardiology';
import {diagnosticToolsEndocrinologyData} from './pdfs/dt/endocrinology';
import {diagnosticToolsDermatologyData} from './pdfs/dt/dermatology';
import {MultiTabItemType, TabItemType} from '../../interfaces/api/content/home';
import {treatmentOptionsNeurologyData} from './pdfs/to/neurology';
import {treatmentOptionsPediatricsData} from './pdfs/to/pediatrics';
import {treatmentOptionsUrologyData} from './pdfs/to/urology';
import {guidelinesNeurologyData} from './pdfs/gl/neurology';
import {guidelinesUrologyData} from './pdfs/gl/urology';
import {guidelinesPediatricsData} from './pdfs/gl/pediatrics';
import {diagnosticToolsUrologyData} from './pdfs/dt/urology';
import {diagnosticToolsPediatricsData} from './pdfs/dt/pediatrics';
import {diagnosticToolsNeurologyData} from './pdfs/dt/neurology';
import {treatmentOptionsOphthalmologyData} from './pdfs/to/ophthalmology';
import {guidelinesOphthalmologyData} from './pdfs/gl/ophthalmology';
import {diagnosticToolsOphthalmologyData} from './pdfs/dt/ophthalmology';
import {store} from '../../redux/store';
import {tr} from '../../translation';

export const staticRecommendations = {
  [TabIds.TreatmentOptions]: {
    name: 'Genio Recommendations',
    [SpecialityType.Cardiology]: treatmentOptionsCardiologyData,
    [SpecialityType.Dermatology]: treatmentOptionsDermatologyData,
    [SpecialityType.Endocrinology]: treatmentOptionsEndocrinologyData,
    [SpecialityType.Neurology]: treatmentOptionsNeurologyData,
    [SpecialityType.Ophthalmology]: treatmentOptionsOphthalmologyData,
    [SpecialityType.Pediatrics]: treatmentOptionsPediatricsData,
    [SpecialityType.Respiratory]: treatmentOptionsRespiratoryData,
    [SpecialityType.Urology]: treatmentOptionsUrologyData,
  },
  [TabIds.Guidelines]: {
    name: 'Genio Recommendations',
    [SpecialityType.Cardiology]: guidelinesCardiologyData,
    [SpecialityType.Dermatology]: guidelinesDermatologyData,
    [SpecialityType.Endocrinology]: guidelinesEndocrinologyData,
    [SpecialityType.Neurology]: guidelinesNeurologyData,
    [SpecialityType.Ophthalmology]: guidelinesOphthalmologyData,
    [SpecialityType.Pediatrics]: guidelinesPediatricsData,
    [SpecialityType.Respiratory]: guidelinesRespiratoryData,
    [SpecialityType.Urology]: guidelinesUrologyData,
  },
  [TabIds.DiagnosticTools]: {
    name: 'Genio Recommendations',
    [SpecialityType.Cardiology]: diagnosticToolsCardiologyData,
    [SpecialityType.Dermatology]: diagnosticToolsDermatologyData,
    [SpecialityType.Endocrinology]: diagnosticToolsEndocrinologyData,
    [SpecialityType.Neurology]: diagnosticToolsNeurologyData,
    [SpecialityType.Ophthalmology]: diagnosticToolsOphthalmologyData,
    [SpecialityType.Pediatrics]: diagnosticToolsPediatricsData,
    [SpecialityType.Respiratory]: diagnosticToolsRespiratoryData,
    [SpecialityType.Urology]: diagnosticToolsUrologyData,
  },
};

export const staticLaboratoryValuesTab: TabItemType = {
  hasspecialty: false,
  rank: 0,
  tab_name: 'Laboratory Values',
  id: -88,
  tab_value: '',
  pdf_link: process.env.REACT_APP_PDF_URL + 'GenioLaboratoryValuesTab.pdf',
};

export const staticCalculatorTab: TabItemType = {
  hasspecialty: false,
  rank: 0,
  tab_name: 'Medical Calculators',
  id: -99,
  tab_value: '',
  calculator_data: [
    {
      id: 0,
      title: 'GFR Calculator (Glomerular Filtration Rate)',
      body:
        "A Glomerular Filtration Rate (GFR) calculator is a tool used to estimate kidney function based on a patient's age, gender, race, and serum creatinine level. It calculates the estimated GFR, which reflects the rate at which blood is filtered by the kidneys per minute, indicating how well the kidneys are functioning. GFR is a key indicator in assessing and monitoring kidney health, guiding clinical decisions in the diagnosis and management of chronic kidney disease (CKD) and other kidney-related conditions. The calculator helps clinicians classify CKD stages, determine the severity of kidney impairment, and adjust treatment plans, including medication dosages that are renally excreted. Regular monitoring of GFR assists in evaluating disease progression, identifying complications, and planning appropriate interventions to preserve kidney function and improve patient outcomes in various clinical settings.\n" +
        '\n' +
        'https://www.kidney.org/professionals/kdoqi/gfr_calculator \n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 1,
      title: 'TIMI Risk Score for STEMI',
      body:
        'Predicts mortality risk in patients with ST-segment elevation myocardial infarction (STEMI). It considers clinical parameters such as age, heart rate, blood pressure, and other factors to guide treatment decisions and predict outcomes.\n' +
        '\n' +
        'https://www.mdcalc.com/calc/99/timi-risk-score-stemi \n' +
        '\n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 2,
      title: 'HAS-BLED Score for Major Bleeding Risk',
      body:
        'This calculator helps assess the risk of major bleeding in patients receiving anticoagulation therapy, particularly those with atrial fibrillation. The HAS-BLED score considers factors such as hypertension, abnormal renal/liver function, stroke history, bleeding history, labile INR, elderly age, and drugs/alcohol use, aiding in decision-making regarding antithrombotic therapy management.\n' +
        '\n' +
        'https://www.mdcalc.com/calc/807/has-bled-score-major-bleeding-risk \n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 3,
      title: "Well's Criteria for Pulmonary Embolism",
      body:
        'Assesses the probability of pulmonary embolism based on clinical criteria. It aids clinicians in determining the need for further diagnostic testing, such as D-dimer assay and imaging studies, to confirm or rule out pulmonary embolism in patients presenting with suspected symptoms.\n' +
        '\n' +
        'https://www.mdcalc.com/calc/115/wells-criteria-pulmonary-embolism \n' +
        '\n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 4,
      title: 'MELD Score (Model for End-Stage Liver Disease)',
      body:
        'The Model for End-Stage Liver Disease (MELD) score is a numerical scoring system used to assess the severity of chronic liver disease and predict short-term mortality in patients with liver dysfunction. It incorporates objective laboratory values: serum bilirubin, serum creatinine, and international normalized ratio (INR) for prothrombin time. The MELD score is particularly valuable in prioritizing patients for liver transplantation, as higher scores correlate with increased mortality risk. By quantifying the severity of liver dysfunction based on these biomarkers, clinicians can stratify patients into different risk categories and optimize management strategies. Regular monitoring of the MELD score helps guide treatment decisions, including the timing of liver transplantation and interventions to manage complications of cirrhosis and other liver diseases. Overall, the MELD score plays a crucial role in clinical decision-making, improving patient outcomes through timely and appropriate management of liver disease.\n' +
        '\n' +
        'https://www.mayoclinic.org/medical-professionals/transplant-medicine/calculators/meld-model/itt-20434705\n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 5,
      title: 'CURB-65 Score for Pneumonia Severity',
      body:
        'Predicts mortality risk in community-acquired pneumonia based on clinical parameters. This score aids in determining the appropriate level of care, such as hospital admission or intensive monitoring, for patients presenting with pneumonia.\n' +
        '\n' +
        'https://www.mdcalc.com/calc/324/curb-65-score-pneumonia-severity \n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 6,
      title: 'ASCVD Risk Estimator Plus',
      body:
        ' The ASCVD Risk Estimator Plus is a tool developed by the American College of Cardiology (ACC) to estimate 10-year risk for atherosclerotic cardiovascular disease (ASCVD) events, including heart attack and stroke. This tool helps clinicians and patients make informed decisions regarding preventive measures and treatment strategies to reduce cardiovascular risk.\n' +
        '\n' +
        'https://tools.acc.org/ascvd-risk-estimator-plus/#!/calculate/estimate/ \n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 7,
      title: 'BODE Index for COPD Prognosis',
      body:
        'Predicts mortality risk in patients with chronic obstructive pulmonary disease (COPD) based on Body mass index, airflow Obstruction, Dyspnea, and Exercise capacity. This index helps stratify COPD patients into different risk categories and guide management decisions, including treatment intensity and monitoring frequency.\n' +
        '\n' +
        '\n' +
        'https://www.mdcalc.com/calc/3916/bode-index-copd-survival \n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 8,
      title: 'ABCD2 Score for Stroke Risk After TIA',
      body:
        'Assesses short-term stroke risk following a transient ischemic attack (TIA), aiding in immediate management and prevention strategies. This score helps clinicians determine the urgency of diagnostic evaluation and initiation of preventive measures to reduce the risk of subsequent stroke in patients who have experienced a TIA.\n' +
        '\n' +
        'https://www.mdcalc.com/calc/357/abcd2-score-tia \n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 9,
      title: 'APACHE II Score (Acute Physiology and Chronic Health Evaluation)',
      body:
        'Evaluates severity of illness in critically ill patients based on physiological parameters and chronic health conditions helping in prognosis assessment and intensive care unit (ICU) resource allocation.\n' +
        '\n' +
        'https://reference.medscape.com/calculator/12/apache-ii \n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 10,
      title: 'Digoxin dosing calculator',
      body:
        "Digoxin is a medication used to treat various heart conditions, including atrial fibrillation, heart failure, and certain types of arrhythmias. The dosing of digoxin can be complex and should be individualized for each patient based on their specific medical history, kidney function, and other factors. It's crucial to follow the guidance and recommendations of a healthcare professional or cardiologist when determining the appropriate dosing for a patient. Dosages are typically calculated in terms of micrograms (mcg) or milligrams (mg). \n" +
        '\n' +
        "To calculate a specific dose of digoxin for a patient, you will need to consider several factors, including the patient's age, weight, kidney function, and the condition being treated. Healthcare providers use established dosing guidelines to determine the appropriate dosage.\n" +
        '\n' +
        'https://globalrph.com/medcalcs/digoxin-dosing-calc/ \n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 11,
      title: 'ASCVD Risk Calculator',
      body:
        "ASCVD stands for atherosclerotic cardiovascular disease. An ASCVD risk calculator estimates an individual's risk of experiencing a heart attack or stroke within a certain period based on various factors like age, gender, cholesterol levels, blood pressure, and smoking status. It's an important tool used by healthcare professionals to assess cardiovascular risk and guide preventive measures.\n" +
        'https://tools.acc.org/ascvd-risk-estimator-plus/#!/calculate/therapy/\n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 12,
      title: 'CHADS2-VASC SCORE: STROKE RISK ASSESSMENT IN AFIB',
      body:
        'The CHA2DS2-VASc score is a clinical risk assessment tool used to estimate the risk of stroke in patients with atrial fibrillation (AFib), a common type of irregular heart rhythm. The acronym "CHA2DS2-VASc" is used to remember the components of the scoring system. Each letter represents a different risk factor, and points are assigned for each factor. The higher the total score, the greater the estimated risk of stroke. Here\'s what each component of the CHA2DS2-VASc score stands for: \n' +
        '\t•\tC - Congestive Heart Failure (1 point): If the patient has congestive heart failure, they are assigned 1 point. \n' +
        '\t•\tH - Hypertension (1 point): If the patient has high blood pressure, they receive 1 point. \n' +
        '\t•\tA2 - Age 75 or older (2 points): Patients aged 75 or older receive 2 points. \n' +
        '\t•\tD - Diabetes Mellitus (1 point): If the patient has diabetes, they are assigned 1 point. \n' +
        '\t•\tS2 - Prior Stroke or TIA (2 points): Patients with a history of stroke or transient ischemic attack (TIA) receive 2 points. \n' +
        '\t•\tV - Vascular Disease (1 point): This includes a history of heart attack, peripheral artery disease, or aortic plaque, and patients with any of these conditions are assigned 1 point. \n' +
        '\t•\tA - Age 65-74 (1 point): Patients aged 65 to 74 receive 1 point. \n' +
        '\t•\tSc - Sex Category (Female) (1 point): Female patients are given 1 point. \n' +
        '\n' +
        'Once all the points are added up, the total score can range from 0 to 9. The higher the score, the greater the estimated risk of stroke. The CHA2DS2-VASc score helps healthcare providers determine whether anticoagulation therapy (blood thinners) is necessary for patients with AFib to reduce the risk of stroke. Generally, patients with a score of 2 or higher are considered at an increased risk of stroke and may benefit from anticoagulation therapy, whereas those with a score of 0 may not require anticoagulation. \n' +
        '\n' +
        "It's important to note that clinical guidelines and recommendations may evolve over time, so it's essential for healthcare providers to stay current with the latest guidelines and tailor their treatment decisions to the individual patient's specific circumstances.\n" +
        '\n' +
        'https://globalrph.com/medcalcs/chads2-vasc-score-stroke-risk-assessment-in-a-fib/ \n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 13,
      title: 'Child-Pugh Score for Cirrhosis Mortality',
      body:
        'It is instrumental in assessing the severity and predicting mortality in patients with cirrhosis. By evaluating parameters such as serum bilirubin, serum albumin, prothrombin time, ascites, and hepatic encephalopathy, it categorizes patients into three classes (A, B, and C), each corresponding to varying degrees of liver dysfunction and mortality risk. Class A patients typically have a more favorable prognosis with high 1-year survival rates, whereas Class C patients face significantly higher mortality rates. This scoring system not only aids in prognostication but also guides clinical management decisions, including the timing of liver transplantation and interventions to manage complications. Regular assessment of the Child-Pugh score allows clinicians to monitor disease progression, assess treatment efficacy, and optimize patient care strategies for those with chronic liver disease and cirrhosis.\n' +
        'https://www.mdcalc.com/calc/340/child-pugh-score-cirrhosis-mortality\n' +
        '\n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 14,
      title: 'Steroid Conversion Calculator',
      body:
        ' It is a practical tool used in clinical settings to facilitate the accurate transition between different corticosteroid medications based on their potency and bioavailability. It is particularly crucial when switching patients from one steroid to another or adjusting doses to achieve equivalent anti-inflammatory or immunosuppressive effects while minimizing the risk of adrenal insufficiency or exacerbation of underlying conditions. By inputting the current dose and type of steroid, clinicians can swiftly determine the equivalent dose of another steroid, considering factors such as relative glucocorticoid potency and duration of action. This calculator streamlines dosing adjustments and ensures therapeutic continuity, enhancing patient safety and treatment efficacy in conditions requiring corticosteroid therapy, such as autoimmune disorders, inflammatory diseases, and adrenal insufficiency.\n' +
        '\n' +
        'https://www.mdcalc.com/calc/2040/steroid-conversion-calculator\n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 15,
      title: 'Ideal Body Weight and Adjusted Body Weight',
      body:
        "Ideal body weight (IBW) and adjusted body weight (ABW) are concepts used in clinical settings, particularly in pharmacology and nutrition, to determine appropriate dosing of medications and interventions for patients who may deviate significantly from average body size. IBW represents the weight at which an individual's body mass index (BMI) is considered normal, often calculated using gender-specific formulas based on height. ABW, on the other hand, adjusts for excess body weight, typically in obese individuals, by subtracting a portion of the excess weight from the total weight to more accurately reflect the lean body mass that influences drug distribution and metabolism. In medical practice, IBW and ABW are crucial for calculating medication dosages, particularly for drugs with a narrow therapeutic index or those requiring precise dosing adjustments based on body size to optimize efficacy and minimize potential adverse effects. These calculations ensure that treatments are tailored to individual patient characteristics, improving safety and therapeutic outcomes across various healthcare settings.\n" +
        '\n' +
        'https://www.mdcalc.com/calc/68/ideal-body-weight-adjusted-body-weight\n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 16,
      title: 'Fibrosis-4 (FIB-4) Index for Liver Fibrosis',
      body:
        'The Fibrosis-4 (FIB-4) index is a non-invasive scoring system used to assess the degree of liver fibrosis in patients with chronic liver disease, particularly hepatitis C and non-alcoholic fatty liver disease (NAFLD). It incorporates readily available clinical parameters, namely age, aspartate aminotransferase (AST) levels, alanine aminotransferase (ALT) levels, and platelet count, into a formula that provides an estimation of liver fibrosis severity. A higher FIB-4 score indicates a greater likelihood of significant fibrosis or cirrhosis, guiding clinicians in determining the need for further evaluation or monitoring, such as liver biopsy or imaging studies. Its simplicity, low cost, and minimal invasiveness make the FIB-4 index a valuable tool in clinical practice for risk stratification, prognosis assessment, and treatment decision-making in patients with chronic liver diseases, contributing to more targeted and timely management strategies aimed at reducing disease progression and improving patient outcomes.\n' +
        '\n' +
        'https://www.mdcalc.com/calc/2200/fibrosis-4-fib-4-index-liver-fibrosis\n' +
        '\n' +
        ' To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 17,
      title: 'Warfarin Dose Estimation Calculator',
      body:
        'A Warfarin dose estimation calculator is a crucial tool in clinical practice for determining initial and maintenance doses of Warfarin, an anticoagulant used to prevent blood clot formation in conditions such as atrial fibrillation, deep vein thrombosis, and prosthetic heart valves. The calculator takes into account factors such as patient demographics (age, weight, gender), concomitant medications that may interact with Warfarin, and genetic variations in CYP2C9 and VKORC1 enzymes, which influence the metabolism and sensitivity to the drug. By inputting these variables, clinicians can quickly generate a personalized dosing regimen that balances therapeutic efficacy with the risk of bleeding, aiming to achieve target international normalized ratios (INR) within the desired therapeutic range. This precision helps optimize patient safety and treatment outcomes, reducing the likelihood of complications associated with both under- and over-anticoagulation, thereby enhancing the management of thromboembolic disorders in clinical settings.\n' +
        ' \n' +
        'https://globalrph.com/medcalcs/warfarin-dose-estimation-calc/\n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 18,
      title: 'Bleeding risk: HEMORR2HAGES Bleeding Risk Score',
      body:
        'The HEMORR2HAGES bleeding risk score is a clinical tool designed to assess the risk of bleeding in patients receiving anticoagulant therapy, particularly warfarin. It evaluates several risk factors: Hepatic or renal disease, Ethanol abuse, Malignancy, Older age (above 75 years), Reduced platelet count or function, Re-bleeding risk, Hypertension (uncontrolled), Anemia, Genetic factors (such as CYP2C9 or VKORC1 polymorphisms), Excessive fall risk, and Stroke. Each factor is assigned a score, with higher scores indicating an increased risk of bleeding. This scoring system helps clinicians make informed decisions about anticoagulation therapy by predicting the likelihood of adverse bleeding events and guiding the intensity of monitoring and preventive measures necessary for patient safety. By identifying patients at higher risk of bleeding, the HEMORR2HAGES score supports personalized treatment strategies that aim to balance the benefits of anticoagulation with the potential risks, ultimately improving the overall management of thrombotic and cardiovascular conditions in clinical practice.\n' +
        '\n' +
        'https://globalrph.com/medcalcs/bleeding-risk-hemorr2hages-bleeding-risk-score/\n' +
        '\n' +
        ' To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 19,
      title: 'Digoxin Dosing Calculator',
      body:
        ' A Digoxin dosing calculator is an essential tool in clinical practice used to determine the appropriate initial and maintenance doses of Digoxin, a medication commonly prescribed for heart failure and certain arrhythmias. It takes into account factors such as patient age, weight, renal function (creatinine clearance), and target serum Digoxin concentration. By inputting these variables, clinicians can calculate a personalized dosing regimen that aims to achieve therapeutic levels of Digoxin while minimizing the risk of toxicity. This calculator ensures precision in medication dosing, as Digoxin has a narrow therapeutic window where subtherapeutic levels may be ineffective and supratherapeutic levels can lead to adverse effects such as arrhythmias or gastrointestinal disturbances. The use of a Digoxin dosing calculator facilitates optimal patient care by tailoring treatment to individual patient characteristics, thereby enhancing therapeutic efficacy and safety in managing cardiac conditions.\n' +
        ' \n' +
        'https://globalrph.com/medcalcs/digoxin-dosing-calc/\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 20,
      title: 'Mentzer Index for Thalassemia',
      body:
        'The Mentzer Index is a calculation used in the differential diagnosis of microcytic anemia, particularly in distinguishing between iron deficiency anemia (IDA) and beta-thalassemia trait (BTT). It is derived by dividing the red blood cell (RBC) count by the mean corpuscular volume (MCV). A Mentzer Index of less than 13 suggests beta-thalassemia trait, while a value greater than or equal to 13 is more indicative of iron deficiency anemia. This index is particularly useful in populations where both conditions are prevalent, aiding clinicians in making informed decisions about further diagnostic testing and appropriate management strategies. By using the Mentzer Index, healthcare providers can efficiently direct resources toward confirming the diagnosis and initiating timely interventions tailored to the specific underlying cause of microcytic anemia, thereby optimizing patient care and outcomes in clinical practice.\n' +
        '\n' +
        'https://www.pediatriconcall.com/calculators/mentzer-index-for-thalassemia\n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 21,
      title: 'Drug Half-life Explained',
      body:
        'Drug half-life refers to the time it takes for the concentration of a drug in the bloodstream to decrease by half. It is a critical pharmacokinetic parameter that helps determine dosing intervals and duration of action for medications. Understanding a drug’s half-life is essential for optimizing therapeutic efficacy and minimizing potential side effects. A shorter half-life typically necessitates more frequent dosing to maintain effective drug levels, whereas a longer half-life allows for less frequent dosing. Moreover, half-life influences the accumulation of a drug in the body and its steady-state concentration, which is crucial for medications requiring consistent levels to achieve therapeutic benefits. Clinicians use knowledge of drug half-life to tailor dosing regimens based on individual patient factors, such as metabolism, renal function, and the desired clinical effect, ensuring safe and effective treatment in various medical conditions.\n' +
        'https://www.drugs.com/article/drug-half-life.html\n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 22,
      title: 'Common Measurement Conversion',
      body:
        'Common measurement conversion is indispensable in healthcare and everyday life, facilitating accurate communication and understanding of quantities across different systems. It involves converting measurements such as length (e.g., inches to centimeters), weight (e.g., pounds to kilograms), volume (e.g., teaspoons to milliliters), and temperature (e.g., Fahrenheit to Celsius). In healthcare, accurate measurement conversions are crucial for prescribing medications, determining appropriate dosages based on patient weight, calculating fluid intake or output, interpreting diagnostic tests, and ensuring patient safety during procedures. For instance, converting weight measurements between pounds and kilograms is vital for medication dosing, especially in pediatrics and critical care settings where precise dosages relative to body weight are essential. Similarly, temperature conversions are necessary for monitoring fever and adjusting treatment protocols. Mastery of measurement conversions enables healthcare professionals to provide optimal care, maintain accuracy in medical records, and enhance overall patient outcomes through precise and effective management.\n' +
        '\n' +
        'https://www.drugs.com/article/measurement-conversions.html\n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 23,
      title: 'Generalised Anxiety Disorder Assessment (GAD-7)',
      body:
        'The assessment of Generalized Anxiety Disorder (GAD) involves systematic evaluation to diagnose and monitor the severity of symptoms associated with excessive worry and anxiety. It typically includes standardized assessment tools such as the Generalized Anxiety Disorder 7-item scale (GAD-7) or other diagnostic criteria from the Diagnostic and Statistical Manual of Mental Disorders (DSM-5). These assessments aim to quantify the frequency and intensity of symptoms such as restlessness, irritability, difficulty concentrating, and physical manifestations like muscle tension and sleep disturbances. Clinicians use the results to establish a baseline, track symptom progression, and guide treatment decisions. Regular assessments help gauge treatment efficacy, identify any worsening of symptoms or emergence of comorbid conditions, and adjust therapeutic strategies accordingly, promoting effective management and improved quality of life for individuals with GAD.\n' +
        '\n' +
        'https://patient.info/doctor/generalised-anxiety-disorder-assessment-gad-7\n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 24,
      title: 'Geriatric Depression Scale (GDS)',
      body:
        'The Geriatric Depression Scale (GDS) is a widely used screening tool specifically designed to assess depression in older adults. It consists of a series of questions that evaluate mood, motivation, social interaction, and physical well-being, tailored to the unique psychological and physical challenges often faced by elderly individuals. The scale helps clinicians detect and monitor depressive symptoms in older adults, providing a structured means to evaluate changes in mental health over time. By identifying symptoms such as sadness, hopelessness, loss of interest in activities, and changes in appetite or sleep patterns, the GDS aids in early detection and intervention for depression, which is crucial in this population due to its association with increased morbidity, impaired quality of life, and higher risk of suicide. Clinicians use the GDS to initiate appropriate treatment strategies, including pharmacotherapy, psychotherapy, and social interventions, to alleviate symptoms and improve overall mental well-being in elderly patients.\n' +
        '\n' +
        'https://patient.info/doctor/geriatric-depression-scale-gds\t\n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
    {
      id: 25,
      title: 'Pregnancy (Due Date) Calculator',
      body:
        "A pregnancy (due date) calculator is a valuable tool used by healthcare providers and expecting parents to estimate the expected date of delivery (EDD) based on the first day of the last menstrual period (LMP) or ultrasound measurements. It calculates the gestational age of the fetus, typically in weeks and days, providing an approximate timeframe for when the baby is likely to be born. This information is essential for prenatal care planning, monitoring fetal development, and preparing for childbirth. Healthcare providers use the estimated due date to schedule prenatal visits, perform necessary screenings and tests at specific gestational ages, and assess fetal growth and well-being throughout pregnancy. Expecting parents find the calculator helpful for tracking pregnancy milestones, preparing for labor and delivery, and making necessary arrangements for the newborn's arrival. Overall, the pregnancy calculator supports informed decision-making and ensures comprehensive care for both mother and baby during pregnancy.\n" +
        '\n' +
        'https://patient.info/doctor/pregnancy-due-date-calculator\n' +
        '\n' +
        'To view the third party content, kindly click on the title above. You will then leave this site and enter a third-party site.',
    },
  ],
};

export const congressFilterData = () => {
  const speciality_id = store.getState()?.auth?.currentUser?.speciality_id;
  switch (speciality_id) {
    case SpecialityType.Cardiology:
      return [
        {
          id: 122,
          name: 'ACC',
        },
        {
          id: 124,
          name: 'AHA',
        },
        {
          id: 123,
          name: 'ESC',
        },
        {
          id: 142,
          name: 'Others',
        },
      ];
    case SpecialityType.Dermatology:
      return [
        {
          id: 138,
          name: 'AAD',
        },
        {
          id: 139,
          name: 'EADV',
        },
        {
          id: 150,
          name: 'Others',
        },
      ];
    case SpecialityType.Endocrinology:
      return [
        {
          id: 140,
          name: 'ADA',
        },
        {
          id: 141,
          name: 'EASD',
        },
        {
          id: 149,
          name: 'ECE',
        },
        {
          id: 148,
          name: 'Others',
        },
      ];
    case SpecialityType.Neurology:
      return [
        {
          id: 155,
          name: 'AAN',
        },
        {
          id: 156,
          name: 'EAN',
        },
        {
          id: 164,
          name: 'ECTRIMS',
        },
        {
          id: 157,
          name: 'Others',
        },
      ];
    case SpecialityType.Ophthalmology:
      return [
        {
          id: 160,
          name: 'AAO',
        },
        {
          id: 161,
          name: 'Euretina',
        },
        {
          id: 162,
          name: 'WOC',
        },
        {
          id: 163,
          name: 'Others',
        },
      ];
    case SpecialityType.Pediatrics:
      return [
        {
          id: 146,
          name: 'AAP',
        },
        {
          id: 147,
          name: 'EAPS',
        },
        {
          id: 152,
          name: 'Others',
        },
      ];
    case SpecialityType.Respiratory:
      return [
        {
          id: 127,
          name: 'ATS',
        },
        {
          id: 126,
          name: 'ERS',
        },
        {
          id: 145,
          name: 'Others',
        },
      ];
    case SpecialityType.Urology:
      return [
        {
          id: 143,
          name: 'AUA',
        },
        {
          id: 144,
          name: 'EAU',
        },
        {
          id: 151,
          name: 'Others',
        },
      ];
  }
};

export const drugsAndDiseasesOptions: MultiTabItemType[] = [
  {key: 'drugs', label: tr('drugs.drugs')},
  {key: 'diseases', label: tr('drugs.diseases')},
];
export const drugsAndDiseasesTab: TabItemType = {
  id: TabIds.Drugs,
  tab_name: 'Drugs & Diseases',
  tab_value: 'drugs',
  rank: 5,
  hasspecialty: false,
  has_articles: false,
  has_topics: false,
  has_protocols: false,
  parent_id: '12',
  multiTabs: drugsAndDiseasesOptions,
};

export const customFiltersKeys = ['publisher'];

const treatmentOptionsRespiratory =
  process.env.REACT_APP_PDF_URL + 'to/respiratory/';

export const treatmentOptionsRespiratoryData = [
  {
    id: 0,
    title: 'Asthma',
    pdf_link: treatmentOptionsRespiratory + 'Asthma_Treatment_Option.pdf',
  },
  {
    id: 1,
    title: 'Bronchitis',
    pdf_link:
      treatmentOptionsRespiratory + 'BRONCHITIS_TREATMENT_DOCUMENT_2.pdf',
  },
  {
    id: 2,
    title: 'Common Cold/Flu',
    pdf_link: treatmentOptionsRespiratory + 'Common_Cold_Treatment_Options.pdf',
  },
  {
    id: 3,
    title: 'COPD',
    pdf_link: treatmentOptionsRespiratory + 'Treatment_Options_for_COPD.pdf',
  },
  {
    id: 4,
    title: 'Lung Cancer',
    pdf_link: treatmentOptionsRespiratory + 'Lung_Cancer_Treatment.pdf',
  },
  {
    id: 5,
    title: 'Otitis Media',
    pdf_link: treatmentOptionsRespiratory + 'OM_Treatment.pdf',
  },
  {
    id: 6,
    title: 'Pharyngitis',
    pdf_link: treatmentOptionsRespiratory + 'Pharyngitis_treatment.pdf',
  },
  {
    id: 7,
    title: 'Pneumonia',
    pdf_link: treatmentOptionsRespiratory + 'Pneumonia_Treatment.pdf',
  },
  {
    id: 8,
    title: 'Sinusitis',
    pdf_link: treatmentOptionsRespiratory + 'SINUSITIS_TREATMENT_OPTION.pdf',
  },
  {
    id: 9,
    title: 'Tuberculosis',
    pdf_link: treatmentOptionsRespiratory + 'TB_Treatment_Options.pdf',
  },
];

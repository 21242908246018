export const baseUrl = process.env.REACT_APP_BASE_URL;
export const baseDashboardUrl = process.env.REACT_APP_BASE_DASHBOARD_URL;

export const api = {
  auth: {
    login: 'auth/login',
    register: 'auth/register',
    config: 'vars',
    updateUser: 'users/update',
    forgotPassword: 'auth/forgot-password',
    resetPassword: 'auth/reset-password',
  },
  news: {
    list: 'news/tabs',
    search: 'news/search',
    newsDetails: 'news',
    drugDetails: 'drugs',
    diseasesDetails: 'diseases',
    drugsSearch: 'drugs/search',
    diseasesSearch: 'diseases/search',
    publishersSearch: 'publishers/search',
    sharedNews: 'shared',
  },
  content: {
    changePassword: 'auth/change-password',
    recommendations: 'news',
    recommendationsDetails: 'recommendations',
    recommendationsByTab: 'recommendations/tab',
    home: 'home',
    filters: 'filters',
  },
  qna: {
    ask: 'qna/ask',
  },
  summaries: {
    getById: 'summaries/',
    getByTab: 'summaries/tab',
    getByDateRange: 'summaries/date-range',
    generateVoice: 'summaries/generate-voice-summary',
  },
  predictions: {
    getById: 'predictions/',
    getByTab: 'predictions/tab',
    getByDateRange: 'predictions/date-range',
  },
  analysis: {
    getById: 'analysis/',
    getByTab: 'analysis/tab',
    getByDateRange: 'analysis/date-range',
  },
  translate: {
    googleTranslate: 'api/google-translate',
  },
};

import React, {useRef, useEffect, useState} from 'react';
import styled, {useTheme} from 'styled-components';
import {SkeletonPlaceholderProps} from '../../interfaces/components/skeletonPlaceholder';
import Skeleton from 'react-loading-skeleton';
import {Theme} from '../../theme/lightTheme';

/**
 * Container for the skeleton placeholder.
 */
const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${({theme}) => theme.border};
  position: relative;
`;

/**
 * SkeletonPlaceholder Component
 *
 * The SkeletonPlaceholder component is designed to create a shimmering placeholder effect,
 * commonly used to represent loading or placeholder content in a visually appealing way.
 *
 * @component
 * @param {SkeletonPlaceholderProps} props - Component props.
 * @returns {JSX.Element} SkeletonPlaceholder component.
 */
const SkeletonPlaceholder: React.FC<SkeletonPlaceholderProps> = ({
  width,
  height,
  borderTopLeft,
  borderTopRight,
  borderBottomLeft,
  borderBottomRight,
  aspectRatio,
  borderRadius = 10,
  marginBottom = 0,
  marginTop = 0,
  marginRight = 0,
  marginLeft = 0,
}: SkeletonPlaceholderProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme() as Theme;

  const getHeight = () => {
    if (height) {
      return height;
    }
    if (aspectRatio) {
      return undefined;
    }
    return '100%';
  };

  return (
    <Container
      ref={containerRef}
      style={{
        width: width || '100%',
        height: getHeight(),
        borderBottomLeftRadius: borderBottomLeft ?? borderRadius,
        borderBottomRightRadius: borderBottomRight ?? borderRadius,
        borderTopLeftRadius: borderTopLeft ?? borderRadius,
        borderTopRightRadius: borderTopRight ?? borderRadius,
        marginBottom,
        marginTop,
        marginRight,
        marginLeft,
        aspectRatio,
      }}>
      <Skeleton
        baseColor={'transparent'}
        highlightColor={theme.homeBackground + '55'}
        height={'100%'}
        width={'100%'}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      />
    </Container>
  );
};

export default React.memo(SkeletonPlaceholder);

const treatmentOptionsUrology = process.env.REACT_APP_PDF_URL + 'to/urology/';

export const treatmentOptionsUrologyData = [
  {
    id: 0,
    title: 'Benign Prostatic Hyperplasia',
    pdf_link:
      treatmentOptionsUrology +
      'Benign_Prostatic_Hyperplasia_Treatment_Options.pdf',
  },
  {
    id: 1,
    title: 'Epididymitis',
    pdf_link: treatmentOptionsUrology + 'Epididymitis_Treatment_Options.pdf',
  },
  {
    id: 2,
    title: 'Male Infertility',
    pdf_link:
      treatmentOptionsUrology + 'Male_Infertility_Treatment_Options.pdf',
  },
  {
    id: 3,
    title: 'Overactive Bladder',
    pdf_link:
      treatmentOptionsUrology + 'Overactive_Bladder_Treatment_Options.pdf',
  },
  {
    id: 4,
    title: 'Prostatitis',
    pdf_link: treatmentOptionsUrology + 'Prostatitis_Treatment_Options.pdf',
  },
  {
    id: 5,
    title: 'Pyelonephritis',
    pdf_link: treatmentOptionsUrology + 'Pyelonephritis_Treatment_Options.pdf',
  },
  {
    id: 6,
    title: 'Urethritis and Cystitis',
    pdf_link:
      treatmentOptionsUrology + 'Urethritis_Cystitis_Treatment_Options.pdf',
  },
  {
    id: 7,
    title: 'Urinary Incontinence',
    pdf_link:
      treatmentOptionsUrology + 'Urinary_Incontinence_Treatment_Options.pdf',
  },
  {
    id: 8,
    title: 'Urinary Retention',
    pdf_link:
      treatmentOptionsUrology + 'Urinary_Retention_Treatment_Options.pdf',
  },
];

const guidelinesRespiratory = process.env.REACT_APP_PDF_URL + 'gl/respiratory/';

export const guidelinesRespiratoryData = [
  {
    id: 0,
    title: 'Asthma',
    pdf_link: guidelinesRespiratory + 'Asthma_Guideline.pdf',
  },
  {
    id: 1,
    title: 'Bronchitis',
    pdf_link: guidelinesRespiratory + 'BRONCHITIS_GUIDELINES_DOCUMENT_3.pdf',
  },
  {
    id: 2,
    title: 'Common Cold/Flu',
    pdf_link: guidelinesRespiratory + 'Common_Cold_Guidelines.pdf',
  },
  {
    id: 3,
    title: 'COPD',
    pdf_link: guidelinesRespiratory + 'COPD_guidelines.pdf',
  },
  {
    id: 4,
    title: 'Lung Cancer',
    pdf_link: guidelinesRespiratory + 'Lung_Cancer_Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Otitis Media',
    pdf_link: guidelinesRespiratory + 'OM_Guidelines.pdf',
  },
  {
    id: 6,
    title: 'Pharyngitis',
    pdf_link: guidelinesRespiratory + 'Pharyngitis_guidelines.pdf',
  },
  {
    id: 7,
    title: 'Pneumonia',
    pdf_link: guidelinesRespiratory + 'Pneumonia_Guidelines.pdf',
  },
  {
    id: 8,
    title: 'Sinusitis',
    pdf_link: guidelinesRespiratory + 'SINUSITIS_GUIDELINES.pdf',
  },
  {
    id: 9,
    title: 'Tuberculosis',
    pdf_link: guidelinesRespiratory + 'TB_Guidelines.pdf',
  },
];

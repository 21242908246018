const diagnosticToolsRespiratory =
  process.env.REACT_APP_PDF_URL + 'dt/respiratory/';

export const diagnosticToolsRespiratoryData = [
  {
    id: 0,
    title: 'Asthma',
    pdf_link: diagnosticToolsRespiratory + 'Asthma_Diagnostic_Tool.pdf',
  },
  {
    id: 1,
    title: 'Bronchitis',
    pdf_link:
      diagnosticToolsRespiratory + 'BRONCHITIS_DIAGNOSIS_DOCUMENT_1.pdf',
  },
  {
    id: 2,
    title: 'Common Cold/Flu',
    pdf_link: diagnosticToolsRespiratory + 'Common_Cold_Diagnosis.pdf',
  },
  {
    id: 3,
    title: 'COPD',
    pdf_link: diagnosticToolsRespiratory + 'COPD_Diagnosis.pdf',
  },
  {
    id: 4,
    title: 'Lung Cancer',
    pdf_link: diagnosticToolsRespiratory + 'Lung_Cancer_Diagnosis.pdf',
  },
  {
    id: 5,
    title: 'Otitis Media',
    pdf_link: diagnosticToolsRespiratory + 'OM_Diagnosis.pdf',
  },
  {
    id: 6,
    title: 'Pharyngitis',
    pdf_link: diagnosticToolsRespiratory + 'Pharyngitits_diagnosis.pdf',
  },
  {
    id: 7,
    title: 'Pneumonia',
    pdf_link: diagnosticToolsRespiratory + 'Pneumonia_Diagnosis.pdf',
  },
  {
    id: 8,
    title: 'Sinusitis',
    pdf_link: diagnosticToolsRespiratory + 'SINUSITIS_DIAGNOSIS.pdf',
  },
  {
    id: 9,
    title: 'Tuberculosis',
    pdf_link: diagnosticToolsRespiratory + 'TB_Diagnostic_Tools.pdf',
  },
];

import {
  GenerateSharedLinkBody,
  GenerateSharedLinkResponse,
  GetDrugDetailsResponse,
  GetNewsDetailsResponse,
  GetSharedLinkDetailsResponse,
  GetTabNewsForm,
  GetTabNewsResponse,
  SearchNewsForm,
} from '../../interfaces/api/tabs';
import {api} from '../api';
import http from '../http';
import {store} from '../../redux/store';
import {TabIds} from '../../utils/enums';

export const news = {
  getTabNews: ({
    tabId,
    page,
    pageSize,
    ...filters
  }: GetTabNewsForm): Promise<GetTabNewsResponse> =>
    new Promise((resolve, reject) => {
      const {speciality_id} = store.getState().auth?.currentUser || {};
      if (!tabId || !speciality_id) {
        throw new Error('tab id and speciality id are required');
      }
      let url = `${api.news.list}`;
      const params = new URLSearchParams();
      params.append('tab_id', tabId.toString());
      params.append('speciality_id', speciality_id.toString());
      Object.keys(filters).forEach(key => {
        if (filters[key]) {
          params.append(key, String(filters[key]));
        }
      });

      if (page) {
        params.append('page', page.toString());
        params.append('pageSize', (pageSize || 20).toString());
      }

      url += `?${params.toString()}`;
      http
        .get(url)
        .then(res => resolve(res as GetTabNewsResponse))
        .catch(e => reject(e));
    }),
  search: ({
    tabId,
    keywords,
    isDiseases,
    page,
    pageSize,
    ...filters
  }: SearchNewsForm): Promise<GetTabNewsResponse> =>
    new Promise((resolve, reject) => {
      const {speciality_id} = store.getState().auth?.currentUser || {};

      const params = new URLSearchParams();
      if (tabId) {
        params.append('tab_id', tabId + '');
      }
      params.append('speciality_id', speciality_id.toString());

      params.append('keywords', (keywords || '').trim());

      Object.keys(filters).forEach(key => {
        if (filters[key]) {
          params.append(key, String(filters[key]));
        }
      });

      if (page) {
        params.append('page', page.toString());
        params.append('pageSize', (pageSize || 20).toString());
      }

      const url = `${
        +(tabId || '') === TabIds.Drugs
          ? isDiseases
            ? api.news.diseasesSearch
            : api.news.drugsSearch
          : api.news.search
      }?${params.toString()}`;

      http
        .get(url)
        .then(res => resolve(res as GetTabNewsResponse))
        .catch(e => reject(e));
    }),

  getNewsDetails: (id: number): Promise<GetNewsDetailsResponse> =>
    new Promise((resolve, reject) => {
      const url = `${api.news.newsDetails}/${id}`;
      http
        .get(url)
        .then(res => resolve(res as GetNewsDetailsResponse))
        .catch(e => reject(e));
    }),
  getDrugDetails: (
    id: number,
    isDiseases?: boolean,
  ): Promise<GetDrugDetailsResponse> =>
    new Promise((resolve, reject) => {
      const url = `${
        isDiseases ? api.news.diseasesDetails : api.news.drugDetails
      }/${id}`;
      http
        .get(url)
        .then(res => resolve(res as GetDrugDetailsResponse))
        .catch(e => reject(e));
    }),
  getPublishersByLetter: ({
    tabId,
    page,
    pageSize,
    ...filters
  }: GetTabNewsForm): Promise<GetTabNewsResponse> =>
    new Promise((resolve, reject) => {
      const {speciality_id} = store.getState().auth?.currentUser || {};
      if (!tabId || !speciality_id) {
        throw new Error('tab id and speciality id are required');
      }
      const params = new URLSearchParams();
      params.append('tab_id', tabId.toString());
      params.append('speciality_id', speciality_id.toString());
      Object.keys(filters).forEach(key => {
        if (filters[key]) {
          params.append(key, String(filters[key]));
        }
      });

      if (page) {
        params.append('page', page.toString());
        params.append('pageSize', (pageSize || 20).toString());
      }

      const url = `${api.news.publishersSearch}?${params.toString()}`;
      http
        .get(url)
        .then(res => resolve(res as GetTabNewsResponse))
        .catch(e => reject(e));
    }),
  generateSharedLink: (
    body: GenerateSharedLinkBody,
  ): Promise<GenerateSharedLinkResponse> =>
    new Promise((resolve, reject) => {
      const url = `${api.news.sharedNews}`;
      http
        .post(url, body)
        .then(res => resolve(res as GenerateSharedLinkResponse))
        .catch(e => reject(e));
    }),
  getSharedLinkDetails: (
    token: string,
  ): Promise<GetSharedLinkDetailsResponse> =>
    new Promise((resolve, reject) => {
      const url = `${api.news.sharedNews}/${token}`;
      http
        .get(url)
        .then(res => resolve(res as GetSharedLinkDetailsResponse))
        .catch(e => reject(e));
    }),
};

const treatmentOptionsNeurology =
  process.env.REACT_APP_PDF_URL + 'to/neurology/';

export const treatmentOptionsNeurologyData = [
  {
    id: 0,
    title: 'Autism Spectrum Disorder',
    pdf_link:
      treatmentOptionsNeurology +
      'Autism_Spectrum_Disorder_Treatment_Options.pdf',
  },
  {
    id: 1,
    title: 'Brain Tumor',
    pdf_link: treatmentOptionsNeurology + 'Brain_Tumor_Treatment_Options.pdf',
  },
  {
    id: 2,
    title: 'Epilepsy',
    pdf_link: treatmentOptionsNeurology + 'Epilepsy_Treatment_Options.pdf',
  },
  {
    id: 3,
    title: 'Dementia',
    pdf_link: treatmentOptionsNeurology + 'Dementia_Treatment_Options.pdf',
  },
  {
    id: 4,
    title: 'Guillain-Barre Syndrome',
    pdf_link:
      treatmentOptionsNeurology +
      'Guillain_Barre_Syndrome_Treatment_Options.pdf',
  },
  {
    id: 5,
    title: 'Headaches',
    pdf_link: treatmentOptionsNeurology + 'Headaches_Treatment_Options.pdf',
  },
  {
    id: 6,
    title: 'Migraine',
    pdf_link: treatmentOptionsNeurology + 'Migraine_Treatment_Options.pdf',
  },
  {
    id: 7,
    title: 'Multiple Sclerosis',
    pdf_link:
      treatmentOptionsNeurology + 'Multiple_Sclerosis_Treatment_Options.pdf',
  },
  {
    id: 8,
    title: "Parkinson's Disease",
    pdf_link:
      treatmentOptionsNeurology + 'Parkinsons_Disease_Treatment_Options.pdf',
  },
  {
    id: 9,
    title: 'Peripheral Neuropathy',
    pdf_link:
      treatmentOptionsNeurology + 'Peripheral_Neuropathy_Treatment_Options.pdf',
  },
];

const treatmentOptionsEndocrinology =
  process.env.REACT_APP_PDF_URL + 'to/endocrinology/';

export const treatmentOptionsEndocrinologyData = [
  {
    id: 0,
    title: 'Diabetes',
    pdf_link: treatmentOptionsEndocrinology + 'Diabetes_Treatment.pdf',
  },
  {
    id: 1,
    title: 'Thyroid Disorders',
    pdf_link: treatmentOptionsEndocrinology + 'Thyroid_disorders_treatment.pdf',
  },
  {
    id: 2,
    title: 'Adrenal Disorders',
    pdf_link: treatmentOptionsEndocrinology + 'Adrenal_disorders_Treatment.pdf',
  },
  {
    id: 3,
    title: 'Pituitary Disorders',
    pdf_link:
      treatmentOptionsEndocrinology +
      'Pituitary_Disorders_Treatment_Options.pdf',
  },
  {
    id: 4,
    title: 'Parathyroid Disorders',
    pdf_link: treatmentOptionsEndocrinology + 'Parathyroid_Treatment.pdf',
  },
  {
    id: 5,
    title: 'Gonadal Disorders',
    pdf_link:
      treatmentOptionsEndocrinology + 'Gonadal_Disorders_Treatment_Options.pdf',
  },
  {
    id: 6,
    title: 'Metabolic Syndrome',
    pdf_link:
      treatmentOptionsEndocrinology + 'Metabolic_Disorder_Treatment.pdf',
  },
];

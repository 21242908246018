const treatmentOptionsCardiology =
  process.env.REACT_APP_PDF_URL + 'to/cardiology/';

export const treatmentOptionsCardiologyData = [
  {
    id: 0,
    title: 'Atrial Fibrillation',
    pdf_link:
      treatmentOptionsCardiology + 'Atrial_fibrillation_treatment_options.pdf',
  },
  {
    id: 1,
    title: 'Acute Coronary Syndrome',
    pdf_link: treatmentOptionsCardiology + 'ASC_Treatment_Option.pdf',
  },
  {
    id: 2,
    title: 'Dyslipidemia',
    pdf_link: treatmentOptionsCardiology + '2-Dyslipidemia_Treatment.pdf',
  },
  {
    id: 3,
    title: 'Heart Failure',
    pdf_link: treatmentOptionsCardiology + 'HF_Treatment.pdf',
  },
  {
    id: 4,
    title: 'Hypertension',
    pdf_link: treatmentOptionsCardiology + 'Hypertention_Treatment.pdf',
  },
  {
    id: 5,
    title: 'Pulmonary Arterial Hypertension',
    pdf_link:
      treatmentOptionsCardiology +
      'PULMONARY_ARTERIAL_HYPERTENSION_TREATMENT.pdf',
  },
  {
    id: 6,
    title: 'Rheumatic Heart Disease',
    pdf_link:
      treatmentOptionsCardiology +
      'Rheumatic_Heart_Disease_Treatment_Option.pdf',
  },
  {
    id: 7,
    title: 'Stroke',
    pdf_link: treatmentOptionsCardiology + 'Stroke_Treatment.pdf',
  },
  {
    id: 8,
    title: 'Thrombosis',
    pdf_link: treatmentOptionsCardiology + 'Thrombosis_Treatment.pdf',
  },
];

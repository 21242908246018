const diagnosticToolsCardiology =
  process.env.REACT_APP_PDF_URL + 'dt/cardiology/';

export const diagnosticToolsCardiologyData = [
  {
    id: 0,
    title: 'Atrial Fibrillation',
    pdf_link: diagnosticToolsCardiology + 'Atrial_Fibrillation_Diagnosis.pdf',
  },
  {
    id: 1,
    title: 'Acute Coronary Syndrome',
    pdf_link: diagnosticToolsCardiology + 'ACS_Diagnosis.pdf',
  },
  {
    id: 2,
    title: 'Dyslipidemia',
    pdf_link: diagnosticToolsCardiology + 'Dyslipidemia_Diagnosis.pdf',
  },
  {
    id: 3,
    title: 'Heart Failure',
    pdf_link: diagnosticToolsCardiology + 'HF_Diagnosis.pdf',
  },
  {
    id: 4,
    title: 'Hypertension',
    pdf_link: diagnosticToolsCardiology + 'Hypertention_Diagnosis.pdf',
  },
  {
    id: 5,
    title: 'Pulmonary Arterial Hypertension',
    pdf_link:
      diagnosticToolsCardiology +
      'PULMONARY_ARTERIAL_HYPERTENSION_DIAGNOSIS.pdf',
  },
  {
    id: 6,
    title: 'Rheumatic Heart Disease',
    pdf_link: diagnosticToolsCardiology + 'RHD_diagnosis.pdf',
  },
  {
    id: 7,
    title: 'Stroke',
    pdf_link: diagnosticToolsCardiology + 'Stroke_Diagnosis.pdf',
  },
  {
    id: 8,
    title: 'Thrombosis',
    pdf_link: diagnosticToolsCardiology + 'Thrombosis_Diagnosis.pdf',
  },
];

const guidelinesUrology = process.env.REACT_APP_PDF_URL + 'gl/urology/';

export const guidelinesUrologyData = [
  {
    id: 0,
    title: 'Benign Prostatic Hyperplasia',
    pdf_link: guidelinesUrology + 'Benign_Prostatic_Hyperplasia_Guidelines.pdf',
  },
  {
    id: 1,
    title: 'Epididymitis',
    pdf_link: guidelinesUrology + 'Epididymitis_Guidelines.pdf',
  },
  {
    id: 2,
    title: 'Male Infertility',
    pdf_link: guidelinesUrology + 'Male_Infertility_Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Overactive Bladder',
    pdf_link: guidelinesUrology + 'Overactive_Bladder_Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Prostatitis',
    pdf_link: guidelinesUrology + 'Prostatitis_Guidelines.pdf',
  },
  {
    id: 5,
    title: 'Pyelonephritis',
    pdf_link: guidelinesUrology + 'Pyelonephritis_Guidelines.pdf',
  },
  {
    id: 6,
    title: 'Urethritis and Cystitis',
    pdf_link: guidelinesUrology + 'Urethritis_Cystitis_Guidelines.pdf',
  },
  {
    id: 7,
    title: 'Urinary Incontinence',
    pdf_link: guidelinesUrology + 'Urinary_Incontinence_Guidelines.pdf',
  },
  {
    id: 8,
    title: 'Urinary Retention',
    pdf_link: guidelinesUrology + 'Urinary_Retention_Guidelines.pdf',
  },
];

import React from 'react';
import styled, {css, keyframes, useTheme} from 'styled-components';
import {Theme} from '../../theme/lightTheme';
import Icon from '../icon';

const rotateAudioBorder = keyframes`
    from { transform: rotate(0deg) translate(-50%, -50%); }
    to { transform: rotate(360deg) translate(-50%, -50%); }
`;

const SIZE = 2.5;

const AudioPlayerWrapper = styled.div`
  display: block;
  width: ${SIZE}rem;
`;

const AudioButton = styled.button<{isError: boolean}>`
  background: linear-gradient(to bottom, #f7f7f7, #e7e7e7);
  border: 0;
  border-radius: 50%;
  box-shadow:
    0 2px 4px ${({theme}) => theme.shadow},
    inset 0 2px 4px #fff;
  color: ${({theme}) => theme.secondary};
  cursor: ${({disabled, isError}) =>
    isError ? 'not-allowed' : disabled ? 'wait' : 'pointer'};
  display: block;
  height: ${SIZE}rem;
  width: ${SIZE}rem;
  position: relative;
  outline: 0;
  ${({isError, disabled}) =>
    isError
      ? css`
          display: flex;
          align-items: center;
          justify-content: center;
        `
      : css`
          &:disabled:before {
            animation: ${rotateAudioBorder} 5s infinite linear;
            border: calc(${SIZE}rem / 16) solid;
            border-color: currentColor transparent;
            border-radius: 50%;
            content: '';
            height: calc(100% + ${SIZE}rem / 8);
            left: 50%;
            opacity: 0.8;
            position: absolute;
            top: 50%;
            transform-origin: 0 0;
            width: calc(100% + ${SIZE}rem / 8);
          }

          i {
            opacity: ${disabled ? 0.25 : 1};
          }
        `}
`;

const PlayPauseIcon = styled.i`
  height: calc(${SIZE}rem / 1.8);
  width: calc(${SIZE}rem / 1.8);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    transform: translate3d(0, 0, 0);
    transition: all 0.3s;
    vertical-align: middle;
  }

  &.-pause:before,
  &.-pause:after {
    border-bottom: calc(${SIZE}rem / 7.27) solid transparent;
    border-left: calc(${SIZE}rem / 4.8) solid currentColor;
    border-top: calc(${SIZE}rem / 7.27) solid transparent;
    height: 0;
  }
  &.-pause:after {
    border-bottom: calc(${SIZE}rem / 7) solid transparent;
    border-top: calc(${SIZE}rem / 7) solid transparent;
  }

  &.-pause:before {
    border-right: 0 solid transparent;
    height: calc(${SIZE}rem / 1.8 - ${SIZE}rem / 3.64);
  }

  &.-play:before,
  &.-play:after {
    border-left: calc(${SIZE}rem * 0.2) solid currentColor;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
    height: calc(${SIZE}rem / 2);
  }

  &.-play:before {
    border-right: calc(${SIZE}rem * 0.05) solid transparent;
  }
`;

const PlayerIcon = ({
  className,
  onClick,
  isReady,
  iconName,
  isError,
}: {
  className?: string;
  onClick: any;
  isReady?: boolean;
  isError?: boolean;
  iconName: 'play' | 'pause';
}) => {
  const theme = useTheme() as Theme;
  return (
    <AudioPlayerWrapper className={className}>
      <AudioButton
        className={'button'}
        isError={isError}
        title="Listen to this file"
        onClick={onClick}
        disabled={!isReady}>
        {isError ? (
          <Icon
            name={'error'}
            size={`calc(${SIZE}rem / 1.8)`}
            color={theme.text.error}
          />
        ) : (
          <PlayPauseIcon className={`-${iconName || 'pause'}`} />
        )}
      </AudioButton>
    </AudioPlayerWrapper>
  );
};

export default PlayerIcon;


const guidelinesCardiology = process.env.REACT_APP_PDF_URL + 'gl/cardiology/';

export const guidelinesCardiologyData = [
  {
    id: 0,
    title: 'Atrial Fibrillation',
    pdf_link: guidelinesCardiology + 'Atrial_Fibrillation_Guidelines.pdf',
  },
  {
    id: 1,
    title: 'Acute Coronary Syndrome',
    pdf_link: guidelinesCardiology + 'ACS_guideline.pdf',
  },
  {
    id: 2,
    title: 'Dyslipidemia',
    pdf_link: guidelinesCardiology + '3-Dyslipidemia_Guidelines.pdf',
  },
  {
    id: 3,
    title: 'Heart Failure',
    pdf_link: guidelinesCardiology + 'HF_Guidelines.pdf',
  },
  {
    id: 4,
    title: 'Hypertension',
    pdf_link: guidelinesCardiology + 'Hypertention_Guideline.pdf',
  },
  {
    id: 5,
    title: 'Pulmonary Arterial Hypertension',
    pdf_link:
      guidelinesCardiology + 'PULMONARY_ARTERIAL_HYPERTENSION_GUIDELINES.pdf',
  },
  {
    id: 6,
    title: 'Rheumatic Heart Disease',
    pdf_link: guidelinesCardiology + 'RHD_recommendation.pdf',
  },
  {
    id: 7,
    title: 'Stroke',
    pdf_link: guidelinesCardiology + 'Stroke_Guidelines.pdf',
  },
  {
    id: 8,
    title: 'Thrombosis',
    pdf_link: guidelinesCardiology + 'Thrombosis_Guidelines.pdf',
  },
];
